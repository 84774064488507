.privacy {
    @apply w-[90%] mx-auto py-[10rem] flex flex-col;
}

.privacy>h1 {
    @apply text-[#01212E] text-[32px] font-[400] max-lg:text-[32px];
}

.privacy>p {
    @apply text-[#01212E] text-[1.6rem] font-[400] my-[2rem] leading-[3rem];
}

.privacy__Container h3 {
    @apply text-[#000] text-[1.6rem] font-[400] leading-[3.5rem];
}

.privacy__List {
    @apply ml-[2rem];
}

.privacy__List p {
    @apply text-[#000] text-[1.6rem] font-[400] leading-[3.5rem];
}

.privacy__Update {
    @apply text-[#000] text-[1.6rem] font-[400] leading-[3.5rem] mt-[3rem];
}