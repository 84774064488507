.button__Common {
    background: linear-gradient(0deg, #9b9aef 0%, #9b9aef 100%), #FFF;
    @apply border-black/80 border-[1px] w-[26.7rem] px-[1rem] py-[1.5rem] text-[#FFF] text-[1.8rem] font-[700] rounded-xl;
}

.button__Common:hover,
.button__Common:focus {
    background: linear-gradient(45deg, #AA3DE1 0%, #684DD3 100%), #fff;
}

.button__Common2 {
    background: transparent;
    @apply border-black/80 border-[1px] w-[26.7rem] px-[1rem] py-[1.5rem] text-[#000] text-[1.8rem] font-[700] rounded-xl;
}

.button__Common2:hover,
.button__Common2:focus {
    background: linear-gradient(45deg, #AA3DE1 0%, #684DD3 100%), #fff;
    @apply text-white;
}

/* .button__Common {
    background: linear-gradient(0deg, #1E1E1E 0%, #1E1E1E 100%), #FFF;
    @apply w-[26.7rem] px-[1rem] py-[1.5rem] text-[#FFF] text-[1.8rem] font-[700] rounded-[5rem];
}

.button__Common:hover, .button__Common:focus {
    background: linear-gradient(45deg, #AA3DE1 0%, #684DD3 100%), #fff;
} */

.button__Byor {
    background: transparent;
    @apply transition-all duration-100 w-[26.7rem] border-black/80 border-[1px] px-[1rem] py-[1.5rem] text-[1.8rem] font-[700] rounded-full;
}

.button__Byor:hover,
.button__Byor:focus {
    background: #9b9aef;
    @apply text-white;
}

.button__BannerCommon {
    @apply border-black/80 border-[1px] bg-white w-[26.7rem] px-[1rem] py-[1.5rem] text-[#FFF] text-[1.8rem] font-[700] rounded-xl;
}

.button__BannerCommon:hover,
.button__BannerCommon:focus {
    background: linear-gradient(45deg, #AA3DE1 0%, #684DD3 100%), #fff;
    @apply border-transparent;
}

/* .button__BannerCommon {
    @apply  bg-white w-[26.7rem] px-[1rem] py-[1.5rem] text-[#FFF] text-[1.8rem] font-[700] rounded-[5rem];
}
.button__BannerCommon:hover, .button__Common:focus {
    background: linear-gradient(45deg, #AA3DE1 0%, #684DD3 100%), #fff;
    @apply border-transparent;
} */

.button__CTA_1 {
    @apply max-xl:w-[40%] max-md:w-[60%] border-black/80 border-[1px] bg-[#d8d8f6] h-24 px-[1rem] py-[1.5rem] text-[#000] text-[1.4rem] font-[400] rounded-xl;
}

.button__CTA_1:hover,
.button__CTA_1:focus {
    @apply bg-[#9b9aef];
}

.button__White {
    @apply bg-[#FFF] w-[26.7rem] px-[1rem] py-[1.5rem] text-[#1E1E1E] text-[1.8rem] font-[700] rounded-[5rem];
}

.login__Btn {
    @apply w-full flex items-center justify-center border-none bg-[#D9D9D9] py-[2rem] px-[6rem] cursor-pointer;
}

.login__Btn img {
    @apply w-[1.8rem] h-[1.8rem];
}

.auth__Button {
    @apply w-full bg-[#042E3E] px-[1rem] py-[1.5rem] text-[#FFF] text-[1.8rem] font-[700];
}

.Button__CommonSquare {
    @apply w-full bg-[#042E3E] px-[1rem] py-[1.5rem] text-[#FFF] text-[1.8rem] font-[700] outline-none;
}

.Button__WhiteSquare {
    @apply w-full px-[6rem] py-[2.5rem] bg-white text-[#1E1E1E] text-center text-[1.8rem] font-[700] uppercase;
}