.cart {
    @apply w-full py-[6rem];
}

.cart__Container {
    @apply w-full flex gap-[5rem];
}

.cart>h1 {
    @apply mb-[5.5rem] pl-[8rem] text-[6.4rem] font-[400] text-[#1E1E1E];
}

.cart>h1>span {
    @apply font-[700] text-[#042E3E];
}

.cart__Left {
    @apply flex-[6] pl-[8rem] flex flex-col gap-[5rem];
}

.cart__Right {
    @apply flex-[4] bg-[#D9D9D9] p-[6rem];
}

.cart__Right>h1 {
    @apply text-[#042E3E] text-[6.4rem] font-[700];
}

.cart__details {
    @apply flex flex-col gap-[3rem] mt-[8rem];
}

.cart__row {
    @apply flex items-center justify-between;
}

.cart__row p {
    @apply text-[#1E1E1E] text-[1.6rem] font-[700] uppercase;
}

.cart__Coupon {
    @apply mt-[6rem] w-full bg-white flex items-center gap-[1rem];
}

.cart__Coupon input {
    @apply w-full bg-transparent text-[#868686] text-[1.6rem] text-center py-[1rem] px-[1rem] outline-none;
}

.cart__Coupon button {
    @apply px-[3rem] h-full text-[1.6rem] text-white font-[700] bg-[#042E3E] uppercase py-[1rem];
}

.cart__CheckoutBtn {
    @apply mt-[1rem] w-full bg-[#042E3E] uppercase text-[1.6rem] font-[700] text-white py-[1.5rem];
}

.cart__PayOptions {
    @apply mt-[3.5rem] flex items-center gap-[1.5rem];
}

.cart__Pay {
    @apply bg-white px-[2.5rem] py-[0.2rem] cursor-pointer;
}

.cart__Pay img {
    @apply w-[5rem] h-[5rem];
}