.storeInside {
    @apply py-[10rem];
}

.storeInside__Container {
    @apply w-[90%] mx-auto flex gap-[5rem];
}

.storeInside__Left {
    @apply flex-[2] flex flex-col gap-[3rem];
}

.storeInside__Left h1 {
    @apply text-[#111] text-[3.2rem] font-[400];
}

.storeInside__Left p {
    @apply text-[#868686] text-[1.6rem] font-[500];
}

.storeInside__Left img {
    @apply w-full object-cover;
}

.storeInside__Right {
    @apply flex-1 bg-[#F9F9F9] py-[5rem] px-[4rem];
}

.storeInside__Right h1 {
    @apply text-[1.6rem] text-[#1E1E1E] font-[500];
}

.storeInside__Form {}

.storeInside__Form form {
    @apply mt-[3rem] flex flex-col gap-[1.5rem];
}

.storeInside__Field input,
textarea {
    @apply outline-none w-full bg-[#EDEDED] px-[1rem] py-[1rem] text-[#616161] text-[1.6rem] font-[500];
}

.storeInside__Wrap {
    @apply flex gap-[2rem];
}