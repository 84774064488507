.forgotPassword {
    @apply w-full min-h-screen flex flex-col items-center justify-center;
}

.forgotPassword__Container {
    @apply bg-[#F9F9F9] p-12 rounded-md max-w-[80rem] min-w-[80rem];
}

.forgotPassowrd__Wrapper {
    @apply flex flex-col gap-16;
}

.forgotPassowrd__Wrapper h3 {
    @apply text-[1.8rem] text-justify;
}

.forgotPassowrd__Input {
    @apply flex flex-col gap-6;
}

.forgotPassowrd__Input label {
    @apply text-[1.4rem] font-[400];
}

.forgotPassowrd__Input input {
    @apply px-[1rem] py-[0.8rem] bg-gray-100 border border-slate-300 rounded-md text-[1.4rem] outline-none;
}