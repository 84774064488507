/* Accordion Common - Home Page : */
.accordion {
    @apply mb-[1rem];
}

.accordion__Container {
    @apply bg-[#F9F9F9] flex items-center justify-between py-[1.6rem] px-[2.4rem] text-[2.2rem] max-lg:text-[1.6rem] font-[400] text-[#000] cursor-pointer;
}

.accordion__Visible {
    @apply mb-[2rem] text-[1.8rem] text-justify font-[300] p-[2rem] bg-[#F9F9F9] max-lg:text-[1.4rem];
}

/* Career Accordion */
.careerAccordion {
    @apply mb-[2rem];
}

.careerAccordion__Card {}

.careerAccordion__Select {
    @apply cursor-pointer bg-[#03455F] text-white flex items-center gap-[14rem] py-[5rem] px-[4.5rem] text-[2.2rem] font-[400];
}

.careerAccordion__Normal {
    @apply bg-[#F9F9F9] flex items-center justify-start gap-[14rem] py-[5.4rem] px-[4.5rem] text-[2.2rem] font-[400] text-[#000] cursor-pointer;
}

.careerAccordion__Text {
    @apply flex-[1] text-[1.8rem] font-[700] uppercase;
}

.careerAccordion__Visible {
    @apply mb-[2rem] flex items-center bg-[#042E3E] py-[2rem];
}

.careerAccordion__VisibleData {
    @apply flex-[3] flex flex-col gap-[3rem] text-white text-[1.6rem] font-[500] p-[2rem];
}

.careerAccordion__Role {
    @apply flex-[7] text-[3.3rem] font-[400];
}