.teams {
    @apply w-full min-h-screen;
}

.teams__Container {
    @apply w-[90%] mx-auto my-[4rem] md:px-custom;
}

/* .teams__Top {}

.teams__Top p {
    @apply text-[#042E3E] text-[1.8rem] font-[700] uppercase;
} */

.teams__Header {
    @apply flex items-center justify-between max-lg:flex-col max-lg:items-start max-lg:gap-[3rem];
}

.teams__Header h1 {
    @apply text-[#01212E] text-[32px] font-[400] max-md:text-[32px];
}

.teams__Header h1 span {
    @apply text-[#01212E] text-[32px] font-[400];
}

.teams__Middle {
    @apply mt-[2rem] mb-[4rem];
}

/* .teams__Middle h4 {
    @apply mb-[2.9rem] text-[#042E3E] text-[1.8rem] font-[700] uppercase;
} */

.teams__Middle p {
    @apply text-[#01212E] text-[14px] font-[400] text-left;
}

.teams__BottomOne {
    @apply mx-auto grid grid-cols-4 gap-[1rem] max-lg:grid-cols-2 max-md:grid-cols-1;
}

.teams__BottomTwo {
    @apply mt-[2rem] w-[50%] mx-auto grid grid-cols-2 gap-[2rem] max-xl:w-[80%] max-lg:grid-cols-1 max-lg:w-[95%];
}