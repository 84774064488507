.checkout {
    @apply mx-auto my-[6rem] w-[80%];
}

.checkout__Wrapper {
    @apply overflow-hidden rounded-xl shadow;
}

.checkout__Body {
    @apply grid grid-cols-1 md:grid-cols-2;
}

.checkout__Contact {
    @apply px-[2rem] py-6 text-gray-900;
}