.about {
    @apply py-[4rem];
}

.about__Container {
    @apply w-[90%] mx-auto md:px-custom;
}

.about__One {
    @apply flex gap-[10rem] max-xl:flex-col;
}

.about__Left {
    @apply flex-1;
}

.about__Left img {
    @apply w-full  object-cover;
}

.about__Right {
    @apply flex-1 flex flex-col justify-center gap-[2rem];
}

.about__Right h4 {
    @apply text-[#042E3E] text-[1.8rem] font-[700] uppercase;
}

.about__Right h1 {
    @apply text-[#000] text-[6.4rem] font-[400] max-md:text-[5.5rem];
}

.about__Right h1 span {
    @apply text-[#042E3E] font-[700];
}

.about__Right p {
    @apply text-[#868686] font-[500] text-[1.6rem] text-justify leading-[2.6rem];
}

.about__Two {
    @apply py-[10rem] flex gap-[2rem] max-xl:flex-col;
}

.about__TwoLeft {
    @apply flex-1 flex flex-col justify-center;
}

.about__TwoLeft h4 {
    @apply text-[#042E3E] text-[1.8rem] font-[700] uppercase;
}

.about__TwoLeft h1 {
    @apply text-[#000] text-[6.4rem] font-[400] max-md:text-[5.5rem];
}

.about__TwoLeft h1 span {
    @apply text-[#042E3E] font-[700];
}

.about__TwoRight {
    @apply flex-1;
}

.about__TwoRight p {
    @apply text-[#868686] font-[500] text-[1.6rem] text-justify leading-[2.6rem];
}

.about__Three {
    @apply pb-[10rem];
}

.about__Three h4 {
    @apply text-[#042E3E] text-[1.8rem] font-[700] uppercase;
}

.about__Three h1 {
    @apply text-[#000] text-[6.4rem] font-[400] max-md:text-[5.5rem];
}

.about__Three h1 span {
    @apply text-[#042E3E] font-[700];
}

.about__ThreeContainer {
    @apply py-[4rem] flex gap-[5rem] max-xl:flex-col;
}

.about__ThreeLeft {
    @apply flex-1 flex flex-col justify-center gap-[2rem];
}

.about__ThreeLeft h4 {
    @apply text-[#042E3E] text-[1.8rem] font-[700] uppercase;
}

.about__ThreeLeft p {
    @apply text-[#868686] font-[500] text-[1.6rem] text-justify leading-[2.6rem];
}

.about__ThreeRight {
    @apply flex-1;
}

.about__ThreeRight img {
    @apply w-full h-[50rem] object-cover max-xl:h-full;
}

.about__Four {
    @apply pb-[10rem];
}

.about__Four>h1 {
    @apply text-[#000] text-[6.4rem] font-[400] mb-[2rem] max-md:text-[5.5rem];
}

.about__Four>h1>span {
    @apply text-[#042E3E] font-[700];
}

.about__PropOne {
    @apply w-full mb-[1.5rem];
}

.about__PropOne p {
    @apply text-[#042E3E] text-[1.8rem] font-[700] max-xl:text-justify;
}

.about__PropOne p span {
    @apply text-[#868686] font-[500] text-[1.8rem] text-justify leading-[2.6rem];
}

.about__Five {
    @apply w-full bg-[#F9F9F9] flex gap-[5rem] justify-center max-xl:flex-col;
}

.about__CommunityLeft {
    @apply px-[10rem] flex-1 flex flex-col items-start justify-center max-xl:py-[1.5rem] max-md:px-[2rem];
}

.about__CommunityLeft>h1 {
    @apply text-[#000] text-[6.4rem] font-[400] mb-[2rem] max-md:text-[5.5rem];
}

.about__CommunityLeft>h1>span {
    @apply text-[#042E3E] font-[700];
}

.about__CommunityLeft>p {
    @apply text-[#868686] font-[500] text-[1.8rem] text-justify leading-[2.6rem] max-md:text-justify;
}

.about__CommunityRight {
    @apply flex-1 w-full max-xl:px-[10rem] max-md:px-[4rem];
}

.about__CommunityRight img {
    @apply w-full h-full object-cover;
}