.OrdersActions__Container {
    @apply w-full flex flex-col gap-5 py-[2rem];
}

.OrdersActions__Container button {
    @apply bg-gray-100 py-[1.2rem] px-[2rem] rounded-lg text-[2.2rem];
}

.OrdersActions__Container button:hover, .OrdersActions__Container button:active {
    @apply bg-gray-200;
}

.OrdersActions__InputForm {
    @apply flex gap-6 text-[2.2rem] items-center;
}

.OrdersActions__InputForm input {
    @apply outline-none bg-slate-100 py-[1.2rem] px-[2rem] text-[2.2rem];
}

.OrdersActions__Result {
    @apply mt-[1.2rem];
}

.OrdersActions__Result > h2 {
    @apply text-[2.4rem];
}

.OrdersActions__Result table {
    @apply w-fit table table-zebra border-2 border-gray-200 text-4xl;
}

.OrdersActions__Result thead th {
    @apply text-4xl px-[8rem] py-[2rem];
}

.OrdersActions__Result tbody td {
    @apply text-4xl py-[2rem] text-center;
}
