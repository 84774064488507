.cl {
    @apply w-full py-[10rem];
}

.cl__Container {
    @apply w-[90%] mx-auto;
}

.cl__Container>h1 {
    @apply text-[#1E1E1E] text-[6.4rem] font-[400] mb-[3rem];
}

.cl__Container>h1>span {
    @apply text-[#042E3E] text-[6.4rem] font-[700];
}

.cl__Wrapper {
    @apply flex gap-[5rem];
}

.cl__Left {
    @apply flex flex-col justify-center flex-1;
}

.cl__Left p {
    @apply text-[#868686] text-justify text-[1.6rem] font-[500];
}

.cl__Right {
    @apply flex-1;
}

.cl__Right img {
    @apply w-full h-[50rem] object-cover;
}