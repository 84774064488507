.footer {
    @apply w-full bg-[#F9F9F9] py-[4rem] md:px-custom max-lg:py-[1.5rem];
}

.footer__Container>hr {
    @apply w-full h-0 border-[#000] border-[1px] mt-[1rem];
}

.footer__Container {
    @apply w-[90%] mx-auto flex flex-col gap-y-[3.6rem];
}

.footer__Top {
    @apply my-[3.6rem] w-full flex items-center gap-[10rem] justify-between;
}

.footer__Top--Left {}

.footer__Top--Left img {
    @apply w-[8.5rem] h-auto;
}

.footer__Top--Right {
    @apply flex items-center gap-[1.5rem];
}

.footer__Top--Right img {
    @apply w-[4.5rem] h-[4.5rem] cursor-pointer;
}

.footer__Middle {
    @apply w-full flex items-start justify-between max-md:grid max-md:grid-cols-2 max-lg:gap-[5rem];
}

.footer__Links h3 {
    @apply text-[16px] font-[400] tracking-[0.1rem] uppercase mb-[3rem] text-[#01212E];
}

.footer__Link {
    @apply mb-[2rem];
}

.footer__Item {
    @apply text-[16px] font-[400] text-[#01212E] leading-8 hover:text-[#052F3F] hover:underline;
}

.footer__Bottom {
    @apply w-full flex items-center gap-[2rem] justify-between max-lg:flex-wrap max-lg:gap-[1.5rem] max-lg:justify-center;
}

.footer__Bottom p {
    @apply my-[3.6rem] text-[16px] font-[400] text-[#01212E] max-lg:my-[0.5rem] leading-8 max-lg:text-center;
}

.footer__Number {
    @apply my-[1.5rem] w-full flex gap-[4rem] justify-end items-end text-[16px] font-[500] text-[#042E3E];
}

/* .footer__Media {
    @apply w-full flex items-center justify-between my-[3.6rem] max-xl:flex-col max-xl:gap-11;
}

.footer__MediaLeft {
    @apply text-[3.6rem] font-[500] text-[#042E3E] max-lg:mb-[1rem] leading-8;
}

.footer__MediaRight {
    @apply flex items-center gap-6 max-xl:flex-col max-xl:grid max-xl:grid-cols-3 max-xl:gap-8;
}

.footer__MediaRight img {
    @apply w-[12rem] h-auto;
} */

.footer__Media {
    @apply w-full flex items-center justify-between my-14 max-xl:flex-col max-xl:gap-2 max-lg:my-[1.5rem];
}

.footer__MediaLeft {
    @apply text-[21px] font-medium text-[#01212E] mb-4 max-xl:text-2xl xl:hidden block;
}

.footer__MediaRight {
    @apply w-full flex flex-wrap items-center justify-center gap-8 xl:justify-between xl:items-center;
}

.footer__MediaRight img {
    @apply w-32 h-auto sm:w-28 md:w-36 lg:w-40;
}