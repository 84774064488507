/* .careers {
    @apply w-full min-h-screen;
}

.careers__Container {
    @apply w-[90%] mx-auto my-[6.5rem];
}

.careers__Top {}

.careers__Top p {
    @apply text-[#042E3E] text-[1.8rem] font-[700] uppercase;
}

.careers__Header {
    @apply flex items-center justify-between;
}

.careers__Header h1 {
    @apply text-[#1E1E1E] text-[6.4rem] font-[400];
}

.careers__Header h1 span {
    @apply text-[#042E3E] text-[6.4rem] font-[700];
}

.careers__Middle {
    @apply mt-[5.5rem] mb-[9rem];
}

.careers__Middle p {
    @apply text-[#868686] text-[1.6rem] font-[500];
}

.careers__Bottom {} */

.career {
    @apply py-[4rem] w-full;
}

.career__Container {
    @apply w-[90%] mx-auto md:px-custom;
}

.career__Top h4 {
    @apply text-[#042E3E] text-[1.8rem] font-[700] uppercase;
}

.career__Top h1 {
    @apply text-[#01212E] text-[32px] font-[400] max-md:text-[32px];
}

.career__Top h1 span {
    @apply text-[#01212E] text-[32px] font-[700];
}

/* .career__Top p {
    @apply text-[#868686] text-[1.6rem] font-[500] max-xl:text-justify;
} */

.career__Bottom {
    @apply mt-[5rem];
}

.career__Bottom h2 {
    @apply text-[#01212E] text-[21px] font-[400];
}

.career__Line {
    @apply my-[1.5rem];
}

.career__Line p {
    @apply text-[#042E3E] text-[1.8rem] font-[700];
}

.career__Line p {
    @apply max-xl:text-justify;
}

.career__Line p span {
    @apply text-[#868686] text-[1.6rem] font-[500];
}

.career__Apply {
    @apply mt-[5rem];
}

.career__Apply h2 {
    @apply text-[#042E3E] text-[1.8rem] font-[700];
}

.career__Apply p {
    @apply text-[#868686] text-[1.6rem] font-[500] max-xl:text-justify my-[1.5rem];
}