.error {
    @apply min-h-screen w-full flex flex-col items-center justify-center gap-[2rem];
}

.error h1 {
    @apply text-[20rem] font-[700] text-[#534FD8];
}

.error h4 {
    @apply text-[2.6rem] font-[500];
}

.error p {
    @apply text-[1.6rem] font-[400] w-[30%] mx-auto text-center;
}

.error__Image {
    @apply relative w-full;
}