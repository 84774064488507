.HireResearchAnalysts {
    @apply py-[4rem] w-full;
}

.HireResearchAnalysts__Container {
    @apply w-[90%] mx-auto md:px-custom;
}

.HireResearchAnalysts__Top {
    @apply w-full flex flex-col gap-5;
}

.HireResearchAnalysts__Top h1 {
    @apply text-[#01212E] leading-tight text-[32px] font-[400] max-md:text-[32px];
}

.HireResearchAnalysts__Top h1 span {
    @apply text-[#01212E] text-[32px] font-[700];
}

.HireResearchAnalysts__Top p {
    @apply text-[#01212E] text-[14px] font-[400] max-xl:text-left;
}

.HireResearchAnalysts__Middle {
    @apply mt-[5rem] space-y-6;
}

.HireResearchAnalysts__Middle h2 {
    @apply text-[#01212E] text-[21px] font-[400];
}