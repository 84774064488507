.po {
    @apply w-[90%] mx-auto py-[10rem] flex flex-col;
}

.po>h1 {
    @apply text-[#01212E] text-[32px] font-[400];
}

.po__Summary {
    @apply text-[14px] text-[#01212E] font-[400] my-[3rem];
}

.po__SecOne {
    @apply mb-[3rem];
}

.po__SecOne h3 {
    @apply text-[#01212E] text-[14px] text-left font-[400] mb-[2rem];
}

.po__SecOne p {
    @apply text-[#01212E] text-[14px] text-left font-[400] mb-[2rem];
}

.po__SecTwo {
    @apply mb-[3rem];
}

.po__SecTwo h3 {
    @apply text-[#01212E] text-[14px] text-left font-[400] mb-[2rem];
}

.po__SecTwo ul {
    @apply w-full;
}

.po__SecTwo ul li {
    @apply text-[#01212E] text-[14px] text-left font-[400] mb-[2rem];
}

.po__SecTwo ul li span {
    @apply text-[#01212E] text-[14px] text-left font-[400] mb-[2rem];
}