.customerDetails {
    @apply w-full h-full;
}

.customerDetails>h1 {
    @apply text-[2.4rem] font-[500];
}

.customerDetails__Container {
    @apply w-full h-full my-[4rem] flex gap-[5rem];
}

.customerDetails__Left {
    @apply flex-[3] flex justify-center py-[3rem];
}

.customerDetails__Left img {
    @apply object-cover w-[30rem] h-[30rem] rounded-full;
}

.customerDetails__Right {
    @apply flex-[7] flex flex-col gap-[4rem];
}

.customerDetails__Row {
    @apply flex;
}

.customerDetails__Row {
    @apply flex items-center gap-[2rem];
}

.customerDetails__Row h3 {
    @apply w-1/3 flex-[3] text-[1.6rem] font-[500] whitespace-nowrap uppercase;
}

.customerDetails__Row input {
    @apply w-1/3 flex-[9] text-[1.6rem] font-[400] px-4 py-2 border-black border-2;
}

.customerDetails__Row p {
    @apply flex-[9] text-[1.6rem] font-[400];
}

.customerDetails__Btn {
    @apply mt-[2rem];
}

.customerDetails__Btn button {
    @apply bg-black px-[6rem] py-[1.5rem] text-white text-[1.6rem] font-[500];
}