.store {
    @apply my-[9rem] w-[90%] mx-auto;
}

.store__Top {
    @apply w-full flex items-center gap-[5rem] justify-between mb-[6rem];
}

.store__Left {
    @apply flex items-center gap-[1rem];
}

.store__Left button {
    @apply uppercase bg-[#EDEDED] px-[1.7rem] py-[1.8rem] text-[#1E1E1E] text-[1.8rem] font-[700] hover:bg-[#c8c6c6];
}

.store__Right {
    @apply flex items-center gap-[1rem];
}

.store__Searchbar {
    @apply flex items-center justify-between gap-[1.5rem] bg-[#EDEDED] w-[30rem] px-[1.7rem] py-[1.8rem];
}

.store__Searchbar img {
    @apply w-[2rem] h-[2rem];
}

.store__Searchbar input {
    @apply bg-transparent outline-none border-none text-[#868686] font-[500] text-[1.6rem];
}

.store__Right button {
    @apply flex justify-between gap-[3rem] items-center bg-[#EDEDED] px-[1.7rem] py-[1.6rem] text-[#1E1E1E] text-[1.8rem] font-[700] uppercase hover:bg-[#c8c6c6];
}

.store__Cards {
    @apply grid grid-cols-4 gap-[1rem] place-items-center;
}