.hero {
	@apply relative w-full overflow-hidden;
}

.hero__slide {
	@apply relative w-full h-[100vh];
}

.hero__image {
	transition: opacity 1s ease-in-out;
	@apply w-full h-full object-cover;
}

/* .hero__slide {
	@apply relative w-full h-[100vh] max-md:h-auto;
}

.hero__image {
	transition: opacity 1s ease-in-out;
	@apply w-full h-full object-cover;
	aspect-ratio: 16 / 9;
	/* Maintain the aspect ratio (adjust as per your image) */
/* object-fit: contain;
} */

.hero__Container {
	transition: opacity 1s ease-in-out;
	animation: fadeIn 1s forwards;
	@apply absolute top-0 left-[5%] right-[5%] flex h-full opacity-0 md:px-custom max-lg:flex-col max-lg:top-[10%];
}

.hero__Left {
	@apply flex-1 flex flex-col items-start justify-center gap-[2rem] max-lg:items-start;
}

.hero__Left h4 {
	letter-spacing: 0.19rem;
	@apply uppercase text-white text-[1.8rem] font-[700] max-lg:text-center;
}

.hero__Left h1 {
	@apply text-[3.2rem] font-[400] text-white max-lg:text-[5rem] max-md:text-[3.2rem] max-lg:text-left;
}

.hero__Left .by-or {
	@apply text-[4.4rem] font-[400] text-white max-lg:text-[5rem] max-md:text-[3rem] max-lg:text-center;
}

.hero__Left .by-or span {
	@apply font-[400];
}

.hero__Left h1 span {
	@apply font-[700] max-lg:mx-[0.5rem];
}

.hero__Left p {
	@apply text-white text-[2.1rem] font-[400] max-lg:text-left;
}

.hero__Right {
	@apply flex-1;
}

.hero__Adjustment {
	@apply mt-[2rem] max-lg:mt-[20rem] max-lg:rounded-[0.4rem] cursor-pointer;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.hero__dots {
	position: absolute;
	bottom: 20px;
	/* Adjust the value as needed */
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 3;
}

.hero__dot {
	width: 10px;
	height: 10px;
	margin: 0 5px;
	border-radius: 50%;
	background-color: #ccc;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.hero__dot.active {
	background-color: #333;
}