.orderDetails {
    @apply my-[9rem] w-[90%] mx-auto;
}

.orderDetails__Container {
    @apply flex gap-[5rem];
}

.orderDetails__Left {
    @apply flex-[6];
}

.orderDetails__Right {
    @apply flex-[4];
}

.orderDetails__Options {
    @apply flex items-center gap-[1rem] mb-[6rem];
}

.orderDetails__Options button {
    @apply uppercase bg-[#EDEDED] px-[1.7rem] py-[1.8rem] text-[#1E1E1E] text-[1.8rem] font-[700] hover:bg-[#c8c6c6];
}

.orderDetails__MainImg {
    @apply w-full h-[70rem] mb-[2rem];
}

.orderDetails__MainImg>img {
    @apply w-full h-[70rem] object-cover;
}

.orderDetails__ImagesThumbnail {
    @apply flex gap-[2rem] items-center h-[14rem] w-[14rem];
}

.orderDetails__thumbnail {
    @apply w-full h-[14rem] object-cover cursor-pointer;
}

.orderDetails__ImagesThumbnail .selected {
    @apply w-full h-[14rem] object-cover border-[0.2rem] border-black rounded-[0.4rem];
}

.orderDetails__Right--Top {
    @apply bg-[#F9F9F9] p-[2rem];
}

.orderDetails__Right--Top>h1 {
    @apply text-[#1E1E1E] text-[2.2rem] font-[400] mb-[3rem];
}

.orderDetails__Right--Top>p {
    @apply text-[#1E1E1E] text-[1.8rem] font-[700] mb-[3rem];
}

.orderDetails__Cart {
    @apply flex items-center gap-[1rem] mb-[1rem];
}

.orderDetails__Counts {
    @apply flex-[1] border-[0.1rem] border-[#000] flex items-center gap-[2rem];
}

.orderDetails__Counts button {
    @apply flex-[1] px-[2rem] py-[1rem] text-[1.6rem] font-[500] cursor-pointer;
}

.orderDetails__Counts span {
    @apply text-[1.8rem];
}

.orderDetails__Cart--Icon {
    @apply flex-[1] bg-[#EDEDED] py-[1.5rem] flex items-center justify-center
}

.orderDetails__Cart--Icon img {
    @apply w-[1.8rem] h-[1.6rem];
}

.orderDetails__Right--Top>button {
    @apply bg-[#042E3E] w-full py-[1.5rem] text-[1.8rem] text-white uppercase font-[700];
}

.orderDetails__Select {
    @apply mt-[6rem] flex flex-col gap-[1rem] w-full;
}

.orderDetails__Select button {
    @apply bg-[#EDEDED] flex items-center justify-between px-[2rem] py-[1.5rem] text-[#1E1E1E] font-[700] text-[1.8rem] uppercase;
}

.orderDetails__Select button img {
    @apply w-[1.5rem] h-[1.5rem];
}

.orderDetails__Right--Bottom {
    @apply w-full mt-[1rem] flex flex-col gap-[1rem];
}

.orderDetails__Right--Bottom button {
    @apply bg-[#EDEDED] flex items-center justify-between px-[2rem] py-[3rem] text-[#1E1E1E] font-[700] text-[1.8rem] uppercase;
    ;
}

.orderDetails__Right--Bottom button img {
    @apply w-[1.5rem] h-[1.5rem];
}

.orderDetails__Cards {
    @apply mt-[10rem] grid grid-cols-4 gap-[1rem] place-items-center;
}