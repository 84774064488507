.banner {
	@apply w-full flex items-center justify-center bg-[#052F3F] min-h-[36rem] py-[2rem];
}

.banner__Container {
	@apply w-[90%] max-md:w-[80%] mx-auto flex items-center justify-between gap-[4rem] max-xl:flex-col md:px-custom;
}

.banner__Left {
	@apply flex-[2] max-lg:flex-1 w-full;
}

.banner__Left h1 {
	@apply leading-tight text-[3.2rem] font-[400] text-[#F4F4F4] max-xl:text-left max-lg:text-[3.2rem] mb-[2rem] max-lg:mb-[2rem];
}

.banner__Left p {
	@apply text-[2.1rem] font-[400] text-[#F4F4F4] max-lg:text-left;
}

.banner__Right {
	@apply flex-[1] flex items-end justify-end max-lg:w-full max-lg:items-start max-lg:justify-start;
}