.Reports__Heading {
    @apply text-[2.2rem] font-bold text-center mb-8;
}

.Report__Sections {
    @apply bg-white shadow-md rounded-md p-4 flex flex-col gap-4;
}

.Report__Sections h3 {
    @apply text-3xl font-semibold;
}

.Report__Sections p {
    @apply text-2xl;
}

.Report__Table {
    @apply w-full mt-[6rem] max-lg:overflow-x-scroll max-lg:hidden max-md:block;
}

.Report__Table>table {
    @apply w-full border-collapse;
}

.Report__Table>table>thead {
    @apply bg-slate-200 border-[0.4rem] border-transparent;
}

.Report__Table>table>thead>tr {
    @apply text-left;
}

.Report__Table>table>thead>tr>th {
    @apply py-[1rem] text-black font-[500] text-[1.6rem] max-md:px-[2rem];
}

.Report__Table>table>thead>tr>th>div {
    @apply flex gap-[1rem] items-center;
}

.Report__Table>table>thead>tr>th>div>svg {
    @apply cursor-pointer;
}

.Report__Table>table>thead>tr>th:first-child {
    @apply pl-[3rem];
}

.Report__Table>table>tbody> {
    @apply bg-slate-400;
}

.Report__Table>table>tbody>tr {
    @apply text-left border-[0.1rem] border-slate-200 hover:bg-slate-200;
}

.Report__Table>table>tbody>tr>td {
    @apply py-[1rem] text-slate-600 font-[400] text-[1.4rem] whitespace-nowrap max-lg:px-[2rem];
}

.Report__Table>table>tbody>tr>td:first-child {
    @apply pl-[3rem];
}