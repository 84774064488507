.overview {}

.overview__Cards {
    @apply grid grid-cols-4 gap-[2rem];
}

.overview__SalesRevenue {
    @apply my-[5rem] bg-slate-100 p-[2rem] rounded-[0.4rem];
}

.overview__SalesRevenue>h1 {
    @apply mb-[2rem] text-[2.2rem] font-[500];
}

.overview__Traffic {
    @apply flex gap-[5rem];
}

.overview__Traffic--Left {
    @apply flex-[1] flex items-center justify-center bg-slate-100 p-[3rem];
}

.overview__Traffic--Right {
    @apply flex-[1] bg-slate-100 p-[3rem];
}