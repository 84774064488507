.products {
    @apply w-full;
}

.products__Top {
    @apply mt-[4rem] bg-slate-100 w-[40rem] rounded-[0.4rem];
}

.products__Top input {
    @apply outline-none bg-transparent py-[1.5rem] px-[2rem] text-[1.4rem];
}

.products__Table {
    @apply w-full mt-[6rem] max-lg:overflow-x-scroll max-lg:hidden max-md:block;
}

.products__Table>table {
    @apply w-full border-collapse;
}

.products__Table>table>thead {
    @apply bg-slate-200 border-[0.4rem] border-transparent;
}

.products__Table>table>thead>tr {
    @apply text-left;
}

.products__Table>table>thead>tr>th {
    @apply py-[1rem] text-black font-[500] text-[1.6rem] max-md:px-[2rem];
}

.products__Table>table>thead>tr>th>div {
    @apply flex gap-[1rem] items-center;
}

.products__Table>table>thead>tr>th>div>svg {
    @apply cursor-pointer;
}

.products__Table>table>thead>tr>th:first-child {
    @apply pl-[3rem];
}

.products__Table>table>tbody> {
    @apply bg-slate-400;
}

.products__Table>table>tbody>tr {
    @apply text-left border-[0.1rem] border-slate-200 hover:bg-slate-200;
}

.products__Table>table>tbody>tr>td {
    @apply py-[1rem] text-slate-600 font-[400] text-[1.4rem] whitespace-nowrap max-lg:px-[2rem];
}

.products__Table>table>tbody>tr>td:first-child {
    @apply pl-[3rem];
}

.products__LoadMore {
    @apply my-[3rem] flex items-center justify-center;
}

.products__LoadMore p {
    @apply text-[1.6rem] cursor-pointer text-blue-500;
}