.Settings__Form {
    @apply flex flex-col gap-8 bg-white shadow-md rounded-md p-4 text-2xl;
}

.Settings__Form h2 {
    @apply text-[2.2rem] font-semibold mb-4;
}

.Settings__Form label {
    @apply flex flex-col gap-2 text-3xl;
}

.Settings__Form input, .Settings__Form select {
    @apply bg-transparent border-2 border-gray-200 outline-none px-[1.4rem] py-[1.2rem] rounded-lg w-full;
}
.Settings__Form input:hover, .Settings__Form input:active {
    @apply border-2 border-gray-300;
}

.Settings__Form button {
    @apply py-[1.4rem] text-[2rem] bg-blue-500 text-white rounded-md mt-8;
}
.Settings__Form button:hover, .Settings__Form button:active {
    @apply bg-blue-600;
}

.Settings__Table>table {
    @apply w-full border-collapse;
}

.Settings__Table>table>thead {
    @apply bg-slate-200 border-[0.4rem] border-transparent;
}

.Settings__Table>table>thead>tr {
    @apply text-left;
}

.Settings__Table>table>thead>tr>th {
    @apply py-[1rem] text-black font-[500] text-[1.6rem] max-md:px-[2rem];
}

.Settings__Table>table>thead>tr>th>div {
    @apply flex gap-[1rem] items-center;
}

.Settings__Table>table>thead>tr>th>div>svg {
    @apply cursor-pointer;
}

.Settings__Table>table>thead>tr>th:first-child {
    @apply pl-[3rem];
}

.Settings__Table>table>tbody> {
    @apply bg-slate-400;
}

.Settings__Table>table>tbody>tr {
    @apply text-left border-[0.1rem] border-slate-200 hover:bg-slate-200;
}

.Settings__Table>table>tbody>tr>td {
    @apply py-[1rem] text-slate-600 font-[400] text-[1.4rem] whitespace-nowrap max-lg:px-[2rem];
}

.Settings__Table>table>tbody>tr>td:first-child {
    @apply pl-[3rem];
}