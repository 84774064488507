.blogPost {
    @apply mb-[6rem] w-full mx-auto;
}

.blogPost>img {
    @apply w-full object-cover h-[65rem];
}

.blogPost__Container {
    @apply w-[90%] mx-auto mt-[9rem];
}

.blogPost__Top {
    @apply flex items-center gap-[5rem];
}

.blogPost__Top--Left {
    @apply flex-[1];
}

.blogPost__Top--Left img {
    @apply w-full h-[42rem] object-cover rounded-md;
}

.blogPost__Top--Right {
    @apply flex-[1];
}

.blogPost__Top--Right h1 {
    @apply mt-[1rem] uppercase text-[#042E3E] text-[1.8rem] font-[700];
}

.blogPost__Top--Right h2 {
    @apply mt-[1rem] text-[#000] text-[6.4rem] font-[400];
}

.blogPost__Top--Right p {
    @apply text-[#868686] text-[1.6rem] font-[500] text-justify;
}

/* .blogPost__Top--Right p {
    @apply capitalize text-[#042E3E] text-[6.4rem] font-[700];
} */

/* .blogPost__Top--Right h1 {
    @apply mt-[1rem] capitalize text-[#000] text-[6.4rem] font-[400];
} */

/* .blogPost__Top--Right h1 span {
    @apply text-[#042E3E] text-[6.4rem] font-[700];
} */

/* .blogPost__Top--Right p {
    @apply mt-[1.5rem] text-[#868686] text-[1.6rem] font-[400];
} */
/* 
.blogPost__Middle {
    @apply my-[9rem];
}

.blogPost__Middle h2 {
    @apply uppercase text-[#042E3E] text-[1.8rem] font-[700];
}

.blogPost__Middle h1 {
    @apply mt-[1rem] text-[#000] text-[6.4rem] font-[400];
}

.blogPost__Middle h1 span {
    @apply text-[#042E3E] text-[2.4rem] font-[700];
}

.blogPost__Middle p {
    @apply mt-[1.5rem] text-[#868686] text-[1.6rem] font-[400];
}

.blogPost__Bottom {
    @apply flex flex-row-reverse items-center gap-[5rem];
}

.blogPost__Bottom--Left {
    @apply flex-[1];
}

.blogPost__Bottom--Left img {
    @apply w-full h-[42rem] object-cover rounded-md;
}

.blogPost__Bottom--Right {
    @apply flex-[1];
}

.blogPost__Bottom--Right h3 {
    @apply uppercase text-[#042E3E] text-[1.8rem] font-[700];
}

.blogPost__Bottom--Right h1 {
    @apply mt-[1rem] text-[#000] text-[6.4rem] font-[400];
}

.blogPost__Bottom--Right h1 span {
    @apply text-[#042E3E] text-[6.4rem] font-[700];
}

.blogPost__Bottom--Right p {
    @apply mt-[1.5rem] text-[#868686] text-[1.6rem] font-[400];
} */

.blogPost__Slider {
    @apply mt-[9rem];
}

.blogPost__Slider--Top {
    @apply flex items-center justify-between gap-[5rem];
}

.blogPost__Slider--Left {
    @apply flex-[1];
}

.blogPost__Slider--Left h3 {
    @apply uppercase font-[700] text-[#000] text-[1.8rem];
}

.blogPost__Slider--Left h1 {
    @apply font-[400] text-[#000] text-[6.3rem];
}

.blogPost__Slider--Left span {
    @apply font-[700] text-[#042000E3E] text-[6.3rem];
}

.blogPost__Slider--Right {
    @apply flex-[1] flex items-end justify-end;
}

.blogPost__Slider--Right button {
    @apply bg-[#042E3E] px-[6rem] py-[1.5rem] uppercase text-[#FFF] font-[700] text-[1.8rem];
}

.blogPost__Slides {
    @apply mt-[5rem];
}