.refund {
    @apply w-[90%] mx-auto py-[10rem] flex flex-col;
}

.refund>h1 {
    @apply text-[#01212E] text-[32px] font-[400] mb-[3rem] max-lg:text-[32px];
}

.refund__Container {
    @apply flex flex-col gap-[2rem] my-[3rem];
}

.refund__Container>h1 {
    @apply text-[#000] text-[3.2rem] font-[400];
}

.refund__Container>p {
    @apply text-[#000] text-[1.6rem] font-[400];
}

.refund__Container>p>span {
    @apply text-[#000] font-[700];
}