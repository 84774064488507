/* .blog-page {
    display: flex;
    padding: 20px;
}

.sidebar {
    width: 20%;
    padding-right: 20px;
}

.search-bar {
    width: 100%;
    padding: 8px;
    margin-bottom: 20px;
}

.categories {
    margin-bottom: 20px;
}

.category {
    padding: 10px;
    cursor: pointer;
}

.category.selected {
    background-color: #e0e0e0;
    font-weight: bold;
}

.top-posts {
    margin-top: 20px;
}

.top-posts h3 {
    margin-bottom: 10px;
}

.top-posts ol {
    padding-left: 20px;
}

.posts {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.post {
    width: 45%;
    border: 1px solid #ccc;
    padding: 10px;
}

.post img {
    width: 100%;
    height: auto;
}

.load-more {
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    cursor: pointer;
} */

.blog {
    @apply w-[90%] mx-auto mb-[6rem] flex gap-[4rem] max-xl:flex-col max-xl:items-center max-xl:justify-center max-xl:gap-10;
}  

.blog__Sidebar {
    @apply sticky top-[14rem] self-start flex-[5] max-xl:hidden max-xl:flex-[1];
}

.blog__Searchbar {
	@apply flex items-center justify-between gap-[1rem] bg-[#EDEDED] px-[1rem] mb-[1rem];
}

.blog__Searchbar input {
	@apply border-none bg-transparent px-[1rem] py-[1.5rem] outline-none text-[#868686] text-[1.5rem] font-[500];
}

.blog__Searchbar img {
	@apply w-[2rem] h-[2rem];
}

.blog__Categories {
	@apply flex flex-col mb-[1rem];
}

.blog__Categories > h1 {
	@apply px-[2rem] py-[2rem] bg-[#EDEDED] text-[#1E1E1E] text-[3.2rem] font-[400];
}

.blog__category {
	@apply px-[2rem] py-[2rem] bg-[#EDEDED] text-[#1E1E1E] text-[2.2rem] font-[400] cursor-pointer;
}

.blog__Categories .selected {
	@apply bg-transparent;
}

.blog__TopPosts {
	@apply flex flex-col gap-4;
}

.blog__TopPosts > h1 {
	@apply px-[2rem] py-[2rem] bg-[#EDEDED] text-[#1E1E1E] text-[3.2rem] font-[400];
}

.blog__TopPosts ol {
}

.blog__TopPosts ol li {
	@apply px-[2rem] py-[1rem] bg-[#EDEDED] text-[#1E1E1E] text-[2.2rem] font-[400] line-clamp-1;
}

.blog__Body {
	@apply flex-[8] max-xl:flex-[11];
}

.blog__Cards {
	/* @apply grid grid-cols-3 gap-x-[1.5rem] gap-y-[3rem] max-lg:grid-cols-2 max-md:grid-cols-1; */
	@apply flex flex-col gap-4;
}

.blog__Cards2 {
    @apply grid grid-cols-3 gap-x-[1.5rem] gap-y-[3rem] max-lg:grid-cols-2 max-md:grid-cols-1;
}

.blog__LoadMoreBtn {
	@apply outline-none bg-[#042E3E] text-[1.8rem] text-[#FFF] font-[700] px-[6rem] py-[1.5rem] mt-[2.5rem];
}
