.reports__Details {
	@apply w-full mx-auto mt-0 mb-[10rem];
}

.reports__DetailsTop {
	@apply flex gap-[2rem] max-xl:w-[90%] max-lg:w-full max-xl:flex-col max-xl:px-[4rem] max-xl:items-center max-xl:justify-center;
}

/* .reports__DetailsTop--Left {
	@apply flex-[8] max-xl:flex-[12];
} */

.reports__DetailsTop--Left h1 {
	@apply w-full mb-[2rem] text-[#042E3E] text-[3.2rem] font-[700];
}

.reports__DetailsTop--Left p {
	@apply font-[400] text-[#01212E] text-[16px] max-lg:w-full;
}

/* 
.reports__DetailsTop--Right {
	@apply flex-[2] flex flex-col gap-[2rem];
} */

.reports__DetailsShare {
	@apply flex gap-[2rem] items-center justify-between;
}

.reports__DetailsShare div {
	@apply flex flex-col items-center gap-1;
}

.reports__DetailsShare div:hover,
.reports__DetailsShare div:hover .share-div-icon {
	@apply text-[#5650D7] transition-all duration-300;
}

.reports__DetailsShare div .share-div-icon {
	@apply text-[#042E3E] font-extrabold w-10 h-10;
}

.reports__DetailsShare div p {
	@apply text-[1.6rem] font-[500];
}

.reports__DetailsShare2 {
	@apply flex gap-[1.2rem] items-center justify-between max-lg:mt-[1rem];
}

.reports__DetailsShare2 div {
	@apply flex items-center gap-1 cursor-pointer;
}

.reports__DetailsShare2 div:hover,
.reports__DetailsShare2 div:hover .share-div-icon {
	/* @apply text-[#01212E] transition-all duration-300; */
}

.reports__DetailsShare2 div .share-div-icon {
	@apply font-extrabold w-[22px] h-[22px];
}

.reports__DetailsShare2 div p {
	@apply text-[16px] font-[400];
}

.reports__DetailsCategory {
	@apply mt-[2rem] flex items-center gap-[2rem];
}

.reports__DetailsCategory button {
	@apply bg-[#F9F9F9] px-[2rem] py-[1rem] text-[#1E1E1E] text-[1.8rem] font-[700] uppercase;
}

.reports__DetailsContainer {
	@apply mt-[3rem] flex gap-[5rem] max-xl:flex-col pt-2 max-lg:w-[90%] max-lg:mx-auto;
}

.reports__DetailsLeft {
	@apply flex-[2] relative max-xl:hidden;
}

.reports__DetailsRight {
	@apply flex-[9] max-xl:flex-[12] pt-4;
	/* max-height: 50rem; */
	/* overflow-y: scroll; */
}

.reports__DetailsRight h1 {
	@apply text-[3.2rem] font-[400] text-[#042E3E];
}

.reports__DetailsRight h2 {
	@apply text-[3.2rem] font-[400] text-[#1E1E1E] mb-[4rem];
}

.reports__DetailsNested {
	@apply pl-[2rem] mt-[2rem];
}

.reports__DetailsNested ul {}

.reports__DetailsNested ul li {
	@apply text-[16px] font-[400] mb-[1.2rem] cursor-pointer capitalize transition-all duration-100 bg-transparent hover:bg-[#052F3F] p-[0.5rem] hover:text-[#F4F4F4] rounded-md;
}

.reports__DetailsNested ul li a {
	@apply w-full;
}

.reprots__TOC {
	@apply flex flex-col gap-[1rem] top-0;
}

.reportsDetailsOne {
	@apply flex flex-col gap-[3rem];
}

.reportsDetailsOne h1 {
	@apply text-[3.2rem] font-[400] text-[#042E3E];
}

.reportsDetailsOne p {
	@apply text-[#868686] text-[1.6rem] font-[500] text-left;
}

.reportsDetailsTwo {
	@apply my-[3rem];
}

.reportsDetailsTwo img {
	@apply my-[1rem] w-full h-[50rem];
}

.reportsDetailsThree {
	@apply my-[3rem];
}

.reportsDetailsThree h1 {
	@apply text-[2.8rem] font-[400] text-[#042E3E] mb-[2rem];
}

.reportsDetailsThree p {
	@apply text-[#868686] text-[1.6rem] font-[500] text-left;
}

.reportsDetailsFour {
	@apply my-[3rem];
}

.reportsDetailsFour h1 {
	@apply text-[3.2rem] font-[400] text-[#042E3E] mb-[2rem];
}

.reportsDetailsFour p {
	@apply text-[#868686] text-[1.6rem] font-[500] text-left;
}

.reportsDetailsFive {
	@apply my-[3rem];
}

.reportsDetailsFive h1 {
	@apply text-[3.2rem] font-[400] text-[#042E3E] mb-[2rem];
}

.reportsDetailsFive p {
	@apply text-[#868686] text-[1.6rem] font-[500] text-left;
}

.reports__TocTable {
	@apply mt-[0.5rem] my-[3rem];
}

.reports__TocTable table {
	@apply border-slate-300 border-[0.2rem] border-separate mx-[5rem];
}

.reports__TocTable table tr {}

.reports__TocTable table tr td {
	@apply py-[1.5rem] px-[1rem] text-[1.6rem] font-[500];
}

@media print {
	.print-padding {
		@apply p-[5rem];
	}
}

.reports__CoverageTable {
	@apply w-full;
}

.reports__CoverageTable table {
	@apply w-full border-separate;
}

.reports__CoverageTable table tbody tr {
	@apply bg-[#9893f5] text-[#FFF];
}

.reports__CoverageTable table td,
.reports__CoverageTable table th {
	@apply text-[4rem];
}

.reports__CoverageTable table tbody tr:nth-child(even) {
	@apply bg-[#7e7bb0] text-[#FFF];
}

.reports__CoverageTable table tbody tr td {
	@apply px-[2rem] py-[1rem] text-[1.8rem] font-[500];
}

.reports__Geography {
	@apply my-[4rem];
}

.reports__Geography h2 {
	@apply text-[3.2rem] font-[400] text-[#1E1E1E] mb-[4rem];
}

.reports__Geography img {
	@apply my-[1rem] w-full;
}

.reports__PortersAnalysis {
	@apply my-[4rem];
}

.reports__PortersAnalysis h2 {
	@apply text-[3.2rem] font-[400] text-[#1E1E1E] mb-[4rem];
}

.reports__PortersAnalysis img {
	@apply my-[1rem] w-full h-[50rem];
}

.reports__FAQ {
	@apply my-[4rem];
}

.reports__FeatureImage {
	@apply flex flex-col items-center justify-center w-full;
}

.reports__FeatureImage h2 {
	@apply text-[21px] font-[400] text-[#052F3F] mb-[2rem];
}

.reports__FeatureImage img {
	@apply w-full h-[50rem];
}

.reports__FeatureImage h3 {
	@apply w-full text-[14px] font-[400] text-[#052F3F] mb-[2rem] text-center italic underline;
}

.reports__OutlookImage {
	@apply w-full flex flex-col mb-16;
}

.reports__OutlookImage img {
	@apply w-full;
}

.reports__OutlookImage h2 {
	@apply text-[21px] font-[400] text-[#01212E] mb-[2rem];
}

.reports__OutlookImage h3 {
	@apply w-full text-[14px] font-[400] text-[#01212E] mb-[0.5rem] text-center py-[1.4rem] italic underline;
}

.reports__MarketSegment {
	@apply w-full flex flex-col mb-16;
}

.reports__MarketSegment img {
	@apply w-full;
}

.reports__MarketSegment h2 {
	@apply text-[21px] font-[400] text-[#01212E] mb-[2rem];
}

.reports__MarketSegment h3 {
	@apply w-full text-[16px] font-[400] text-[#01212E] mb-[0.5rem] text-center py-[1.4rem] italic underline;
}

.reports__MarketSegment h4 {
	@apply w-full text-[14px] font-[400] text-[#01212E] mb-[0.5rem] text-left py-[1.4rem];
}

.reports__Paragraphs {
	@apply flex flex-col gap-[2rem] py-[1.4rem];
}


.reports__Paragraphs p {
	@apply text-[#01212E] text-[14px] font-[400] cursor-pointer text-left max-lg:text-left;
}

.reports__Paragraphs ul {
	@apply list-disc flex flex-col gap-[2rem];
}

.reports__Paragraphs li,
.reports__Paragraphs li p,
.reports__Paragraphs li span {
	@apply text-[#01212E] text-[14px] font-[400] cursor-pointer text-left;
}

.reports__Paragraphs li {
	margin: 0;
	/* border: 1px solid red; */
	padding: 0 0 0 3.2rem;
	list-style: none;
	background-image: url("../../../public/BulletIcon01.png");
	background-repeat: no-repeat;
	background-position: left 0.6rem;
	background-size: 16px;
}

.reports__BulletPoints ul {
	@apply list-disc flex flex-col gap-[2rem];
}

.reports__BulletPoints li,
.reports__BulletPoints li p,
.reports__BulletPoints li span {
	@apply text-[#01212E] text-[14px] font-[400] cursor-pointer text-left;
}

/* .reports__BulletPoints li {
	list-style: none;
}
.reports__BulletPoints li::before {
	content: '';
	display: inline-block;
	height: 1.5rem;
	width: 1.5rem;
	background-image: url('../../../public/BulletIcon01.png');
} */

.reports__BulletPoints li {
	margin: 0;
	/* border: 1px solid red; */
	padding: 0 0 0 3.2rem;
	list-style: none;
	background-image: url("../../../public/BulletIcon01.png");
	background-repeat: no-repeat;
	background-position: left 0.6rem;
	background-size: 16px;
}

.reports__FindingsSection {
	@apply flex flex-col gap-2 mb-16 max-xl:overflow-x-scroll;
}

.reports__FindingsSection p {
	@apply text-[14px] font-[400] text-[#01212E] text-left;
}

.reports__FindingsSection h2 {
	@apply text-[21px] font-[400] text-[#01212E] mb-[0.5rem];
}


.reports__FindingsSection table {
	@apply w-full border-separate;
}

.reports__FindingsSection thead th {
	@apply text-[16px] font-[400] text-[#01212E] border-b-[0.3rem] border-[#042E3E];
}

.reports__FindingsSection thead tr th:nth-child(1) {
	@apply w-[25%];
}

.reports__FindingsSection tbody tr:nth-child(odd) {
	@apply bg-[#F0F0F0];
}

.reports__FindingsSection tbody td {
	@apply text-[16px] font-[400] text-[#01212E] h-fit leading-normal text-left italic;
}

.reports__FindingsSection tbody tr:last-child td {
	@apply border-b-[0.3rem] border-[#F0F0F0];
}

.reports__TOC {
	@apply flex flex-col gap-2 mb-16;
}

.reports__TOC h2 {
	@apply text-[21px] font-[400] text-[#01212E] mb-[2rem];
}

.reports__TOC div {
	@apply text-[14px] font-[400] text-[#01212E] text-left bg-[#F0F0F0] px-10 italic rounded-lg;
}

.reports__FAQ {
	@apply flex flex-col gap-2;
}

.reports__FAQ h2 {
	@apply text-[21px] font-[400] text-[#01212E] mb-[2rem];
}

.reports__FAQ div {
	@apply text-[14px] font-[400] text-[#01212E] text-left;
}

#faqs {
	@apply flex flex-col gap-6;
}

#faqs p:nth-child(odd) {
	@apply font-[600];
}

.reports__Modal {
	@apply flex flex-col gap-4;
}

.reports__Modal label {
	@apply text-[1.2rem] font-[400] text-[#042E3E];
}

.reports__Modal input,
.reports__Modal textarea {
	@apply w-full border rounded-lg px-4 py-3 text-[1.2rem] font-[400] text-[#1E1E1E] bg-white;
}

/* .reports__Modal button {
	@apply px-4 py-3 text-[1.4rem] font-[400] border-[1px] border-gray-300 w-full text-black bg-white rounded-lg;
}

.reports__Modal button:hover {
	@apply bg-gray-200;
} */

/* .reports__Modal button[type="submit"] {
	@apply px-4 py-3 text-[1.4rem] font-[400] text-[#FFF] bg-[#042E3E] rounded-lg;
}

.reports__Modal button[type="submit"]:hover {
	@apply bg-[#042E3E]/80;
} */