.newsDetails {
    @apply py-[6rem] w-[60%] mx-auto max-md:w-[80%];
}

.newsDetails>h2 {
    @apply text-[#000] text-[3.4rem] font-[400] text-justify mb-[2rem];
}

.newsDetails__Time {
    @apply text-[#868686] font-[500] text-[1.6rem] text-justify leading-[2.6rem];
}

.newsDetails__Para {
    @apply text-[#000] font-[400] text-[1.6rem] text-justify leading-[2.6rem] my-[2rem];
}
.newsDetails__Para a span {
    @apply text-violet-700 hover:text-violet-700 cursor-pointer font-[400] text-[1.6rem] text-justify leading-[2.6rem] my-[2rem];
}

.newsDetails>p>span {
    @apply text-[#868686] hover:text-violet-700 cursor-pointer font-[400] text-[1.6rem] text-justify leading-[2.6rem] my-[2rem];
}

.newsDetails table {
    @apply min-w-full border-collapse border leading-normal;
}

.newsDetails table thead tr th {
    @apply py-[2rem] text-[1.6rem] font-[700] text-black px-[1rem] border border-black border-b-[0.2rem] border-b-black text-left w-2/6;
}

.newsDetails table tbody tr td {
    @apply py-[2rem] text-[1.6rem] font-[400] text-black border px-[1rem] border-black w-2/6;
}

.newsDetails__TableCaption {
    @apply w-full text-[1.4rem] text-[#868686] font-[400] text-center my-[2rem];
}

.newsDetails__Bottom h2 {
    @apply text-[#000] text-[3.4rem] font-[700] text-justify mb-[2rem];
}

.newsDetails__Bottom p {
    @apply text-[1.6rem] font-[400] text-black text-justify;
}