@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--padding-x: 10rem;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	font-size: 62.5%;
	scroll-behavior: smooth;
}

a {
	text-decoration: none;
}

body {
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	font-style: normal;
	scroll-behavior: smooth;
	background: #fff;
}

.loader {
	width: 80px;
	height: 40px;
	border-radius: 100px 100px 0 0;
	position: relative;
	overflow: hidden;
}

.loader:before {
	content: '';
	position: absolute;
	inset: 0 0 -100%;
	background: radial-gradient(farthest-side,
			#ffd738 80%,
			#0000) left 70% top 20%/15px 15px,
		radial-gradient(farthest-side, #020308 92%, #0000) left 65% bottom 19%/12px 12px,
		radial-gradient(farthest-side, #ecfefe 92%, #0000) left 70% bottom 20%/15px 15px,
		linear-gradient(#9eddfe 50%, #020308 0);
	background-repeat: no-repeat;
	animation: l5 2s infinite;
}

@keyframes l5 {

	0%,
	20% {
		transform: rotate(0);
	}

	40%,
	60% {
		transform: rotate(0.5turn);
	}

	80%,
	100% {
		transform: rotate(1turn);
	}
}

a {
	text-decoration: none;
}

/* Custom scrollbar styles */
::-webkit-scrollbar {
	width: 1px;
	height: 1px;
	/* For horizontal scrollbars */
	background-color: #4a00ff;
}

::-webkit-scrollbar-thumb {
	background-color: #4a00ff;
	/* Change to desired color */
	border-radius: 9999px;
}

::-webkit-scrollbar-track {
	background: #4a00ff;
	/* Change to desired color */
}

/* For Firefox */
* {
	scrollbar-width: thin;
	scrollbar-color: #4a00ff;
	/* Thumb color and track color */
}


/* NOTISTACK */
#notistack-snackbar {
	@apply text-[1.6rem] font-[500];
}