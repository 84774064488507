.sidebar {
    @apply w-full min-h-screen max-h-screen p-[3.5rem] border-r-2 border-slate-300;
}

.sidebar__Container {
    @apply w-full h-full;
}

.sidebar__Container h1 {
    @apply text-[2.2rem] font-[500];
}

.sidebar__Items {
    @apply mt-[2rem];
}

.sidebar__Items ul {
    @apply flex flex-col gap-y-[1rem];
}

.sidebar__Items ul li {
    @apply flex items-center gap-4 text-[1.6rem] font-[400] px-[1.5rem] py-[1rem] hover:bg-slate-200 cursor-pointer rounded-[0.5rem];
}

.sidebar__Item--clickable {
    @apply flex justify-between items-center gap-[1rem];
}

.sidebar__SubItem {
    @apply px-[1rem];
}

.sidebar__SubItem li p {
    @apply overflow-hidden line-clamp-1;
}