.cookie {
    @apply w-[90%] mx-auto py-[10rem] flex flex-col;
}

.cookie>h1 {
    @apply text-[#01212E] text-[32px] font-[400] max-lg:text-[32px];
}

.cookie__LastUpdatd {
    @apply text-[14px] text-[#01212E] font-[400] my-[3rem];
}

.cookie__SecOne {
    @apply text-[#01212E] text-[14px] text-left font-[400] mb-[3rem];
}

.cookie__SecTwo {
    @apply mb-[2rem];
}

.cookie__SecTwo h3 {
    @apply text-[#01212E] text-[14px] text-left font-[400] mb-[2rem];
}

.cookie__SecTwo p {
    @apply text-[#01212E] text-[14px] text-left font-[400] mb-[2rem];
}

.cookie__SecThree {
    @apply mb-[2rem];
}

.cookie__SecThree h3 {
    @apply text-[#01212E] text-[14px] text-left font-[400] mb-[2rem];
}

.cookie__SecThree p {
    @apply text-[#01212E] text-[14px] text-left font-[400] mb-[2rem];
}

.cookie__SecThree ul {
    @apply w-full;
}

.cookie__SecThree ul li {
    @apply text-[#01212E] text-[14px] text-left font-[400] mb-[2rem];
}

.cookie__SecThree ul li span {
    @apply text-[#01212E] text-[14px] text-left font-[400];
}

.cookie__SecFour {
    @apply mb-[2rem];
}

.cookie__SecFour h3 {
    @apply text-[#01212E] text-[14px] text-left font-[400] mb-[2rem];
}

.cookie__SecFour p {
    @apply text-[#01212E] text-[14px] text-left font-[400] mb-[2rem];
}

.cookie__ConWrapper {
    @apply text-[14px] text-left font-[400] mb-[2rem];
}

.cookie__ConWrapper a {
    @apply ml-[1rem] text-[14px] text-left font-[400];
}

.cookie__ConWrapper address {
    @apply max-w-[65rem];
}