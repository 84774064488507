.ProductDetails__Container {
    @apply w-full flex flex-col gap-5 py-8;
}

.ProductDetails__Container button {
    @apply bg-gray-100 py-3 px-8 rounded-lg text-2xl;
}

.ProductDetails__Container button:hover,
.ProductDetails__Container button:active {
    @apply bg-gray-200;
}

.ProductDetails__InputForm {
    @apply flex gap-6 text-2xl items-center;
}

.ProductDetails__InputForm input {
    @apply outline-none bg-slate-100 py-3 px-8 text-2xl;
}

.ProductDetails__Card {
    @apply mt-6 p-6 border border-gray-200 rounded-lg shadow-lg bg-white;
}

.ProductDetails__Card h2 {
    @apply text-3xl mb-4;
}

.ProductDetails__Card p {
    @apply text-xl mb-2;
}

.ProductDetails__Variant {
    @apply mt-4 p-4 border-t border-gray-200;
}

.ProductDetails__Variant p {
    @apply text-xl;
}
