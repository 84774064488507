.resetPassword {
    @apply w-full min-h-screen flex items-center justify-center;
}

.resetPassword__Wrapper {
    @apply bg-[#F9F9F9] p-12 rounded-md max-w-[80rem] min-w-[50rem] flex flex-col gap-[1rem];
}

.resetPassword__Wrapper h3 {
    @apply text-[1.8rem] text-justify;
}

.resetPassword__Input {
    @apply flex flex-col gap-4;
}

.resetPassword__Input label {
    @apply text-[1.4rem] font-[400];
}

.resetPassword__Input input {
    @apply px-[1rem] py-[0.8rem] bg-gray-100 border border-slate-300 rounded-md text-[1.4rem] outline-none;
}