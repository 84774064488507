.rdp {
    @apply w-[40%] mx-auto py-[10rem] space-y-6;
}

.rdp>h2 {
    @apply text-[3.4rem] font-[500] mb-[2rem] uppercase;
}

.rdp__Inputs {
    @apply flex flex-col gap-[1.5rem];
}

.rdp__InputWrapper label {
    @apply text-[1.6rem] font-[400] text-gray-700;
}

.rdp__InputWrapper input,
textarea {
    @apply mt-1 block w-full rounded-md border-[0.1rem] border-gray-300 p-[1rem] text-[1.6rem];
}

.rdp__Selects {
    @apply flex flex-col gap-[0.5rem];
}

.rdp__Selects label {
    @apply text-[1.6rem] font-[400] text-gray-700;
}

.rdp__addContent {
    @apply flex flex-col gap-[1rem];
}

.rdp__addContent label {
    @apply text-[1.6rem] font-[400] text-gray-700;
}

.btn__Styles {
    @apply text-[1.4rem] font-[500] px-[2rem] py-[1rem] bg-blue-600 text-white rounded-md outline-none cursor-pointer w-full;
}

.rdp__Selects .select option {
    @apply text-[1.4rem];
}