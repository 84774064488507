.news {
    @apply w-full;
}

.news>img {
    @apply w-full h-[70rem] object-cover;
}

.news__Wrapper {
    @apply mt-[12rem] w-[90%] mx-auto;
}

.news__Wrapper>h3 {
    @apply text-[#042E3E] text-[1.8rem] font-[700] mb-[1rem] uppercase;
}

.news__Wrapper>p {
    @apply text-[#868686] text-[1.6rem] font-[500] text-justify;
}

.news__Wrapper h1 {
    @apply text-[#1E1E1E] text-[6.4rem] font-[400] my-[3rem];
}

.news__Wrapper h1 span {
    @apply text-[#042E3E] text-[6.4rem] font-[700];
}

.news__Wrapper ol {
    @apply mb-[3rem] list-disc ml-[2rem];
}

.news__Wrapper ol li {
    @apply text-[#868686] text-[1.6rem] font-[500] text-justify mb-[2rem];
}

.news__CardsWrapper {
    @apply mt-[10rem] mb-[5rem];
}

.news__Cards {
    @apply mt-[4rem] grid grid-cols-3 gap-[5rem];
}

.news__CardsWrapper>h3 {
    @apply text-[#042E3E] text-[1.8rem] font-[700] mb-[1rem] uppercase;
}