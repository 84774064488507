.register {
    @apply w-full min-h-screen;
}

.register__Container {
    @apply w-full h-full mx-auto flex gap-[15rem] justify-between;
}

.register__Left {
    @apply pl-[8rem] flex-1 min-h-screen flex flex-col gap-y-[2.6rem] items-start justify-center;
}

.register__Left>img {
    @apply w-[8.5rem] h-auto;
}

.register__header {
    @apply w-full;
}

.register__header h1 {
    @apply text-[#1E1E1E] text-[2.2rem] font-[400];
}

.register__header p {
    @apply text-[#1E1E1E] text-[1.6rem] font-[500];
}

.register__buttons {
    @apply mt-[2.8rem] flex justify-between gap-[1rem] w-full;
}

.register__form {
    @apply w-full;
}

.register__form form {
    @apply w-full flex flex-col gap-[1rem];
}

.form__Input {
    @apply w-full bg-[#D9D9D9] flex gap-[1rem] px-[1.5rem];
}

.form__Input input {
    @apply h-full text-[1.6rem] font-[500] text-[#868686] py-[1.5rem] w-full bg-transparent outline-none;
}

.register__Right {
    @apply flex-1 bg-[#065AD8] min-h-screen flex items-center justify-center object-cover;
}

.register__Others {
    @apply mt-[2.3rem] flex items-center justify-between;
}

.register__Others>h3 {
    @apply text-[#1976D2] text-[1.6rem] font-[500];
}

/* Checkbox Styling */
.checkbox__container {
    user-select: none;
    @apply flex items-center justify-center relative cursor-pointer pl-[3rem];
}

.checkbox__container>p {
    @apply text-[#868686] text-[1.6rem];
}

.checkbox__container .checkbox__container input {
    @apply absolute opacity-0 cursor-pointer h-0 w-0;
}

.checkbox__container .checkmark {
    @apply absolute top-0 left-0 h-[2rem] w-[2rem] bg-[#D9D9D9] rounded-[0.4rem];
}

.checkbox__container input:checked~.checkmark {
    @apply bg-[#1a73e8];
}

.checkbox__container .checkmark:after {
    content: "";
    @apply hidden absolute;
}

.checkbox__container input:checked~.checkmark:after {
    @apply block;
}

.checkbox__container .checkmark:after {
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    @apply left-[0.7rem] top-[0.4rem] w-[0.6rem] h-[1rem] border-white;
}

.register__createAccount {
    @apply w-full flex justify-center items-center mt-[8rem];
}

.register__createAccount p {
    @apply text-[#868686] text-[1.6rem] font-[500];
}

.register__createAccount p span {
    @apply text-[#1976D2] text-[1.6rem] font-[500] cursor-pointer;
}


.register__alreadyMember {
    @apply w-full flex justify-center items-center mt-[4rem];
}

.register__alreadyMember p {
    @apply text-[#868686] text-[1.6rem] font-[500];
}

.register__alreadyMember p span {
    @apply ml-[0.5rem] text-[#1976D2] text-[1.6rem] font-[500] cursor-pointer;
}