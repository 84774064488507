.items {
    @apply w-full py-[5rem];
}

.items__Container {
    @apply w-[90%] mx-auto;
}

.items__Box {
    @apply flex gap-[5rem];
}

.items__Tabs {
    @apply flex gap-[1rem] mb-[2rem];
}

.items__Tabs button {
    @apply bg-black text-[#FFF] px-[5rem] py-[1.5rem] w-[20rem] text-[1.8rem] rounded-[0.4rem] font-[500] cursor-pointer;
}

.items__Left {
    @apply flex-[2];
}

.items__Left>h1 {
    @apply text-[3.4rem] font-[700] uppercase;
}

.items__Row {
    @apply flex gap-[1rem];
}

.items__radios {
    @apply my-[3rem] flex-[2] bg-[#f5f3f3] px-[1rem] pb-[1rem];
}

.items__radios h3 {
    @apply my-[1.5rem] text-[1.8rem] font-[500] uppercase;
}

.items__radios ul {
    @apply grid grid-cols-1 gap-[1.5rem];
}

.items__radios ul li {
    @apply text-[1.4rem];
}

.items__radios ul li label {
    @apply cursor-pointer flex items-center gap-[1rem];
}

.items__checks {
    @apply my-[3rem] flex-[3];
}

.items__checks {
    @apply p-[1.5rem];
}

.items__subcategories {}

.items__subcategories>h2 {
    @apply text-[1.8rem] font-[500] uppercase;
}

.items__Right {
    @apply flex-[1];
}

.items__Options {
    @apply mt-[2rem] flex gap-[1rem];
}

.items__OptionSearch {
    @apply flex flex-col gap-[1rem];
}

.items__OptionSearch p {
    @apply text-[1.4rem] font-[500];
}

.items__SearchBar {
    @apply flex items-center px-[2rem] gap-[1rem] rounded-[0.4rem] bg-[#EDEDED] w-[25rem];
}

.items__SearchBar img {
    @apply w-[2rem] h-[2rem];
}

.items__SearchBar input {
    @apply w-full py-[1.5rem] bg-transparent outline-none text-[1.4rem] font-[400];
}

.items__Custmom {}

.items__Custmom h1 {
    @apply text-[3.4rem] font-[700] uppercase;
}