.Orders__Container {
    @apply flex flex-col gap-5;
}

.Orders__Heading {
    @apply text-[2.4rem] font-[500];
}

.Orders__BtnContainer {
    @apply flex gap-10;
}

.Orders__NavBtns {
    @apply bg-gray-100 px-10 py-5 text-2xl rounded-lg;
}

.Orders__NavBtns:hover, .Orders__NavBtns:active {
    @apply bg-gray-200;
}

.Orders__BtnActive {
    @apply bg-gray-200;
}