.BusinessMatchmaking {
	@apply py-[4rem] w-full;
}

.BusinessMatchmaking__Container {
	@apply w-[90%] mx-auto md:px-custom;
}

.BusinessMatchmaking__Top {
	@apply w-full flex flex-col;
}

.BusinessMatchmaking__Top h1 {
    @apply text-[32px] font-[400] max-md:text-[5.5rem];
}

.BusinessMatchmaking__Top h1 span {
    @apply text-[#01212E] text-[32px] font-[700];
}

.BusinessMatchmaking__Top p {
    @apply text-[#01212E] text-[14px] font-[500] max-xl:text-justify;
}

.BusinessMatchmaking__Middle {
    @apply mt-[5rem] space-y-6;
}

.BusinessMatchmaking__Middle h2 {
    @apply text-[#01212E] text-[21px] font-[700];
}
