.capabilities {
    @apply w-full;
}

.capabilities__Banner {
    @apply w-full h-[68rem];
}

.capabilities__Banner img {
    @apply w-full h-full object-cover;
}

.capabilities__Details {
    @apply w-[90%] mx-auto my-[9rem];
}

.capabilities__Details h3 {
    @apply text-[#042E3E] text-[1.8rem] font-[700] mb-[1rem];
}

.capabilities__Details h1 {
    @apply text-[#1E1E1E] text-[6.4rem] font-[400] mb-[3rem];
}

.capabilities__Details h1 span {
    @apply text-[#042E3E] text-[6.4rem] font-[700];
}

.capabilities__Details p {
    @apply text-[#868686] text-[1.6rem] font-[500] text-justify;
}

.capabilities__Tags {
    @apply w-[70%] mx-auto mb-[9rem];
}

.capabilities__Tags button {
    @apply min-w-[20rem] max-w-[30rem] flex items-center justify-between px-[2.5rem] py-[1.5rem] bg-[#D9D9D9] border-none rounded-[10rem] text-[#1E1E1E] text-[1.8rem] font-[700] uppercase gap-[2rem] transition duration-300;
}

.capabilities__Tags button img {
    @apply w-[2rem];
    transition: filter 0.3s;
}

.capabilities__Tags button:hover {
    @apply bg-black text-white;
}

.capabilities__Tags button:hover img {
    filter: brightness(0) invert(1);
}

.capabilities__RowOne {
    @apply grid grid-cols-4 place-items-center justify-center gap-[1.5rem] mb-[2rem];
}

.capabilities__RowTwo {
    @apply grid grid-cols-3 place-items-center px-[15rem] mb-[2rem];
}

.capabilities__RowThree {
    @apply grid grid-cols-2 place-items-center px-[25rem];
}

.capabilities__Bottom {
    @apply w-[90%] mx-auto mb-[9rem] items-center flex gap-[5rem];
}

.capabilities__Left {
    @apply flex-1;
}

.capabilities__Left h3 {
    @apply text-[#042E3E] text-[1.8rem] font-[700] mb-[1rem];
}

.capabilities__Left h1 {
    @apply text-[#000] text-[6.4rem] font-[400] mb-[3rem];
}

.capabilities__Left h1 span {
    @apply text-[#042E3E] font-[700];
}

.capabilities__Left p {
    @apply text-[#868686] font-[600] text-[1.6rem];
}

.capabilities__Right {
    @apply flex-1;
}