.disclaimerPolicy {
    @apply w-[90%] mx-auto py-[10rem] flex flex-col;
}

.disclaimerPolicy>h1 {
    @apply text-[#01212E] text-[32px] font-[400] mb-[3rem];
}

.disclaimerPolicy__SecOne {
    @apply mb-[2rem];
}

.disclaimerPolicy__SecOne h3 {
    @apply text-[#01212E] text-[14px] text-left font-[500] mb-[2rem];
}

.disclaimerPolicy__SecOne p {
    @apply text-[#01212E] text-[14px] text-left font-[400] mb-[2rem];
}