.navbar {
	@apply w-full sticky top-0 bg-[#EDEDED] z-10;
}

.navbar__Container {
	@apply w-[90%] mx-auto py-[3rem] flex items-center justify-between gap-[5rem] max-xl:gap-[8rem];
}

.navbar__Left img {
	@apply w-[8.5rem] h-auto;
}

.navbar__Right {
	@apply flex items-center gap-[7rem] max-xl:gap-[3rem];
}

.navbar__Links {
	@apply flex gap-[7rem];
}

.navbar__Links a {
	@apply text-[1.6rem] font-[500];
}

.navbar__Icons {
	@apply flex gap-[2rem] items-center cursor-pointer;
}

.navbar__IconCover {
	@apply p-[1.5rem] rounded-full;
}

.navbar__User {
	@apply rounded-full;
}

.navbar__User img {
	@apply w-[4.5rem] h-[4.5rem] object-cover rounded-full;
}

.navbar__SearchInputContainer {
	@apply rounded-[4rem] bg-[#dcd9d9] px-[2rem] py-[0.5rem] w-[90rem] flex items-center gap-[2rem];
}

.navbar__SearchInputContainer>input {
	@apply bg-transparent w-full py-[0.8rem] text-[1.4rem] outline-none border-none;
}

.navbar__AvatarContainer {
	@apply relative;
}

.navbar__Avatar {
	@apply max-w-[5rem] max-h-[5rem] rounded-full;
}

.navbar__Avatar span {
	@apply w-full text-[1.4rem];
}

.navbar__AvatarDropdown {
	@apply absolute top-[105%] right-0 py-[2rem] px-[3rem] rounded-[1rem] bg-[#D9D9D9] flex flex-col items-start justify-start gap-[2rem];
}

.navbar__AvatarDropdownItemMain {
	@apply flex gap-[1rem] items-center text-[1.6rem] font-[500] text-[#868686];
}

.navbar__AvatarSmall {
	@apply max-w-[3rem] max-h-[3rem] rounded-full;
}

.navbar__AvatarSmall span {
	@apply w-full text-[1.1rem];
}

.navbar__AvatarDropdownItem {
	@apply flex items-center gap-[1.5rem] text-[#1E1E1E] text-[1.2rem] font-[400];
}

.navbar__AvatarDropdownItem img {
	@apply w-[1.4rem] h-[1.4rem];
}

/* ------------ NEW DROPDOWN STYLING------------- */

.navbar__Dropdown {
	@apply relative inline-block;
}

.navbar__DropdownContent {
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	@apply hidden absolute bg-[#042E3E] text-[#FFF] z-10 min-w-[20rem];
}

.navbar__Dropdown:hover .navbar__DropdownContent {
	@apply block text-[#FFF];
}

.navbar__DropdownItem {
	@apply relative;
}

.navbar__SubDropdownContent {
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	@apply hidden absolute left-[100%] top-0 z-10 min-w-[20rem] bg-[#042E3E] text-[#FFF];
}

.navbar__DropdownItem:hover .navbar__SubDropdownContent {
	@apply block;
}

.navbar__Link,
.navbar__DropdownLink {
	@apply px-[1.6rem] py-[1.2rem] block;
}

.navbar__DropdownLink:hover {
	@apply bg-[#01212E];
}

/* ------------------ */
.nav__box {}

.nav__BoxOne {}

.nav__BoxOne>h1 {
	@apply text-white text-[1.8rem] font-[700] uppercase;
}

.nav__BoxLinks {
	@apply py-[2.5rem] grid grid-cols-4 gap-[1.5rem];
}

.nav__BoxLink {
	@apply text-[#FFF] text-[1.8rem] font-[700] uppercase whitespace-nowrap px-[2.5rem] py-[4.5rem] bg-[#2550616e] cursor-pointer hover:bg-[#3771876e];
}

.nav__BoxThree {
	@apply py-[3rem] text-white flex items-center justify-between gap-[2rem];
}

.nav__BoxThree--Left {
	@apply flex-[3];
}

.nav__BoxThree--Left h1 {
	@apply text-white text-[3.2rem] font-[400];
}

.nav__BoxThree--Left p {
	@apply mt-[2rem] text-white text-[1.6rem] font-[500] leading-[2rem];
}

.nav__BoxThree--Right {
	@apply flex-[1] flex items-center justify-end;
}

.nav__BoxFour {
	@apply py-[3rem] text-white flex items-center justify-between gap-[2rem];
}

.nav__BoxFour--left {
	@apply flex-[3] flex items-center gap-[5rem];
}

.nav__Box--C {
	@apply flex flex-col gap-[1.5rem];
}

.nav__Box--C h1 {
	@apply text-[1.8rem] text-white font-[700];
}

.nav__Box--C p {
	@apply text-[1.6rem] text-white font-[500] flex gap-[1rem];
}

.nav__Box--C span {
	@apply hover:text-slate-300 cursor-pointer;
}

/* ------------------- Render Categories ------------------- */

.renderCategory__name {
	@apply text-[#FFF] text-[1.4rem] font-[700] uppercase p-[2rem] cursor-pointer;
}

/* .renderCategory__C {
	@apply flex flex-col overflow-y-scroll text-[#FFF] text-[1.1rem] font-[700] leading-[2.6rem] uppercase p-[2rem] cursor-pointer;
} */

.renderCategory__Child {
	@apply p-[1rem] bg-[#2550616e] cursor-pointer text-center text-[#FFF] text-[1.1rem] font-[700] leading-[2.6rem] uppercase;
}

::-webkit-scrollbar {
	width: 3px;
}

::-webkit-scrollbar-thumb {
	background-color: #000000;
	border-radius: 100000px;
	border: 3px solid #000000;
}

::-webkit-scrollbar-track {
	background: #5351d5;
}

/* New Navbar Styles : */
.navbar__SearchInputContainer {
	@apply rounded-[4rem] bg-[#dcd9d9] px-[2rem] py-[0.5rem] w-[90rem] flex items-center gap-[2rem];
}

.navbar__SearchInputContainer>input {
	@apply bg-transparent w-full py-[0.8rem] text-[1.4rem] outline-none border-none;
}

.navbar__Icons {
	@apply flex gap-[2rem] items-center cursor-pointer;
}

.navbar__IconCover {
	@apply p-[1.5rem] rounded-full;
}

.nav__MainLinks {
	@apply flex flex-col gap-[4rem] max-xl:gap-[2rem] mt-4 items-center font-medium md:flex-row md:mt-0 rtl:space-x-reverse;
}

.nav__MainLinks li a {
	@apply flex items-center justify-between w-full py-2 px-3 font-[400] text-[#01212E] text-[16px];
}

.nav__MainLinks li button {
	/* @apply ; */
}

.nav__dropdownDetails {
	@apply my-[3rem] flex items-center gap-[5rem] justify-between text-[#F4F4F4] text-[1.8rem] font-[700];
}

.new__byor__btn {
	@apply flex items-center justify-between w-full py-2 px-3 font-[400] text-[#01212E] text-[16px] hover:bg-[#052F3F] rounded-full hover:text-[#F4F4F4] shadow-sm shadow-[#F4F4F4];
}

.new__byor__btn {
	background-color: transparent !important;
	color: #01212E !important;
}

.new__byor__btn:hover {
	background-color: #052F3F !important;
	color: white !important;
}

.new__byor__btn:visited {
	background-color: transparent !important;
	color: #01212E !important;
}