.contact {
    @apply w-full py-[4rem] md:px-custom;
}

.contact__Container {
    @apply w-[90%] mx-auto flex gap-[5rem] items-center max-xl:flex-col-reverse;
}

.contact__Left {
    @apply flex-1 w-full;
}

.contact__Right {
    @apply flex-1 w-full;
}

.contact__Right>h1 {
    @apply text-[1.7rem] text-[#1E1E1E] font-[500];
}

.contact__Right>form {
    @apply my-[3rem] w-full;
}

.contact__Right>form>.contact__FormFields {
    @apply flex flex-col gap-[1rem] mb-[1rem] w-full;
}

.contact__FormField {
    @apply flex flex-col gap-[1rem] w-full;
}

.contact__FormField label {
    @apply text-[#01212E] text-[14px] font-[400];
}

.contact__FormField input,
textarea {
    @apply outline-none w-full bg-[#EDEDED] px-[1rem] py-[1rem] text-[#616161] text-[1.6rem] font-[500];
}

.form-control {
    @apply outline-none;
}