.ProductActions__Container {
    @apply flex flex-col gap-4 mt-10 text-2xl w-fit;
}

.ProductActions__FormGroup {
    @apply grid grid-cols-2 text-[2rem];
}

.ProductActions__FormGroup input {
    @apply bg-slate-100 py-[1rem] px-[1.4rem] outline-none;
}

.ProductActions__FormGroup select {
    @apply bg-slate-100 py-[1rem] px-[1.4rem] outline-none w-full;
}

.ProductActions__BtnGroup {
    @apply grid grid-cols-2 grid-rows-2 text-[2rem] gap-4;
}

.ProductActions__BtnGroup button {
    @apply bg-gray-200 px-[1.4rem] py-[1.2rem] rounded-lg;
}
.ProductActions__BtnGroup button:hover, .ProductActions__BtnGroup button:active {
    @apply bg-gray-300;
}