.adminDashboard {
    @apply w-full min-h-screen;
}

.adminDashboard__Wrapper {
    @apply w-full mx-auto flex gap-[2rem];
}

.adminDashboard__Sidebar {
    @apply flex-[2];
}

.adminDashboard__Body {
    @apply flex-[10] p-[4rem];
}