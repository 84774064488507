.agreement {
	@apply mx-auto py-[6rem];
}

.text__Wrapper {
	@apply flex flex-col gap-[1rem] mb-[1rem];
}

.text__Wrapper>h2 {
	@apply text-[#1E1E1E] text-[14px] font-[400] text-justify;
}

.text__Wrapper p {
	@apply text-[#868686] text-[14px] font-[400] text-justify;
}

.text__Wrapper ul {
	@apply list-disc ml-[2rem];
}

.text__Wrapper ul li {
	@apply text-[#868686] text-[14px] font-[400] text-justify;
}

.text__Wrapper ul li:first-child {
	@apply mb-[1rem];
}

.text__Wrapper ol {
	@apply list-disc ml-[2rem];
}

.text__Wrapper ol li {
	@apply text-[#868686] text-[14px] font-[400] text-justify;
}

.text__Wrapper ol li:first-child {
	@apply mb-[1rem];
}

.text__Wrapper ol li strong {
	@apply mb-[1rem];
}

.agreement__Contact {
	@apply flex flex-col gap-[1rem];
}

.agreement__Contact>h2 {
	@apply text-[#1E1E1E] text-[16px] font-[400] text-justify;
}

.agreement__Contact p {
	@apply text-[#868686] text-[14px] font-[400] text-justify;
}

.agreement__Contact address {
	@apply text-[#1E1E1E] text-[14px] font-[400] text-justify;
}

.agreement__phone {
	@apply flex gap-[0.5rem];
}


/* .naics-dropdown{
	max-height: 40rem;
	height: 40rem;
	min-height: 0rem;
} */

.text__Wrapper_New {
	@apply flex flex-col gap-[1rem] mb-[1rem];
}

.text__Wrapper_New>h2 {
	@apply text-[#1E1E1E] text-[16px] font-[400] text-justify;
}

.text__Wrapper_New p {
	@apply text-[#868686] text-[14px] font-[400] text-justify;
}

.text__Wrapper_New ul {
	@apply list-disc ml-[2rem];
}

.text__Wrapper_New ul li {
	@apply text-[#868686] text-[14px] font-[400] text-justify;
}

.text__Wrapper_New ul li:first-child {
	@apply mb-[1rem];
}

.text__Wrapper_New ol {
	@apply list-decimal ml-[2rem];
}

.text__Wrapper_New ol li {
	@apply text-[#868686] text-[14px] font-[400] text-justify;
}

.text__Wrapper_New ol li:first-child {
	@apply mb-[1rem];
}

.text__Wrapper_New ol li strong {
	@apply mb-[1rem];
}