.home {}

.home__Reports {
	@apply mt-[9rem] w-[90%] mx-auto md:px-custom;
}

.home__Reports--Top {
	@apply mb-[5.5rem] flex items-start gap-[2rem] justify-between max-lg:w-full max-lg:flex-col max-lg:text-left;
}

.home__Reports--Left p {
	@apply text-[#042E3E] text-[1.8rem] font-[700];
}

.home__Reports--Left h1 {
	@apply text-[#1E1E1E] text-[3.2rem] font-[400] max-lg:text-[3.2rem] max-lg:text-left max-lg:font-[400];
}

.home__Reports--Left h1 span {
	@apply text-[#042E3E] text-[3.2rem] font-[700] max-lg:text-[3.2rem] max-lg:text-left max-lg:font-[700];
}

.home__Workflow {
	@apply w-full mt-[9rem] flex items-center gap-[5rem] max-xl:flex-col-reverse;
}

.home__Workflow--Left {
	@apply w-full flex-[1] pl-[10rem] max-lg:p-[3rem] max-xl:w-[90%] mx-auto;
}

.home__Workflow--Top {
	@apply mb-[3.8rem];
}

.home__Workflow--Top p {
	@apply text-[#042E3E] text-[1.8rem] font-[700];
}

.home__Workflow--Top h1 {
	@apply text-[#1E1E1E] text-[6.4rem] font-[400];
}

.home__Workflow--Top h1 span {
	@apply text-[#042E3E] text-[6.4rem] font-[700];
}

.home__WorkflowTabs {
	@apply w-full;
}

.home__WorkflowTabs ul {
	@apply flex items-start justify-start max-xl:flex-col max-xl:m-0 max-xl:p-0 max-xl:gap-[2rem] max-xl:w-full;
}

.home__WorkflowTabs ul li {
	@apply max-xl:w-full flex-[1] text-[#042E3E] text-[1.8rem] font-[700] uppercase pb-[1rem] border-b-[1rem] cursor-pointer;
}

.active__Tab {
	@apply border-b-[#1E1E1E] w-full;
}

.default__Tab {
	@apply border-b-[#E1E1E1] w-full;
}

.home__Workflow--TabData {
	@apply mt-[2rem] text-[1.6rem] font-[500] text-[#868686];
}

.home__Workflow--Right {
	@apply flex-[1] max-xl:w-[90%] max-xl:mx-auto;
}

.home__Workflow--Right img {
	@apply h-full w-full object-cover;
}

/* .home__Workflow {
    @apply w-full mt-[9rem] flex items-center gap-[5rem];
}

.home__Workflow--Left {
	@apply flex-[1] pl-[8rem];
}

.home__Workflow--Top {
	@apply mb-[3.8rem];
}

.home__Workflow--Top p {
	@apply text-[#042E3E] text-[1.8rem] font-[700];
}

.home__Workflow--Top h1 {
	@apply text-[#1E1E1E] text-[6.4rem] font-[400];
}

.home__Workflow--Top h1 span {
	@apply text-[#042E3E] text-[6.4rem] font-[700];
}

.home__WorkflowTabs {
}

.home__WorkflowTabs ul {
	@apply flex items-center;
}

.home__WorkflowTabs ul li {
	@apply flex-[1] text-[#042E3E] text-[1.8rem] font-[700] uppercase pb-[1rem] border-b-[1rem] cursor-pointer;
}

.active__Tab {
	@apply border-b-[#1E1E1E];
}

.default__Tab {
	@apply border-b-[#E1E1E1];
}

.home__Workflow--TabData {
	@apply mt-[2rem] text-[1.6rem] font-[500] text-[#868686];
}

.home__Workflow--Right {
	@apply flex-[1];
}

.home__Workflow--Right img {
    @apply h-full w-full object-cover;
} */

.home__Services {
	@apply mt-[9rem] w-[90%] px-[2rem] max-lg:w-full mx-auto md:px-custom;
}

.home__Services--Top {
	@apply mb-[4rem] max-lg:w-full;
}

.home__Services--Top p {
	@apply text-[#042E3E] text-[1.8rem] uppercase font-[700] max-lg:text-left;
}

.home__Services--Top h1 {
	@apply text-[#000] text-[3.2rem] font-[400] max-lg:text-left max-lg:font-[400] max-lg:text-[3.2rem];
}

.home__Services--Top h1 span {
	@apply text-[#042E3E] font-[700];
}

.home__Services--Bottom {
	@apply min-h-[35rem];
}

.home__Wcu {
	@apply mt-[9rem] xl:w-[90%] max-xl:w-[95%] mx-auto md:px-custom;
}

.home__Wcu>h1 {
	@apply text-[#000] text-[6.4rem] font-[400] max-lg:text-center max-lg:mb-[5rem] max-lg:text-[4rem] max-lg:font-[700];
}

.home__Wcu>h1>span {
	@apply text-[#042E3E] font-[700];
}

.home__Wcu .home__Wcu--Cards {
	@apply mt-[4.5rem] grid grid-cols-3 gap-[20rem] max-xl:hidden;
}

.home__Banner {
	@apply mt-[9rem] w-full;
}

.home__Faq {
	@apply w-[90%] mx-auto my-[9rem];
}

.home__Faq>h1 {
	@apply mb-[4rem] text-[6.4rem] font-[799] text-[#042E3E] max-lg:text-center max-lg:text-[4rem];
}

/* --------- Slider Styles ---------- */

.swiper {
	@apply w-full h-full;
}

.swiper-slide {
	/* @apply ; */
}

.swiper-wrapper {
	@apply min-h-[40rem];
}

.swiper-button-next,
.swiper-button-prev {
	background-color: white;
	background-color: rgba(255, 255, 255, 0.5);
	right: 10px;
	padding: 30px;
	color: #000 !important;
	fill: black !important;
	stroke: black !important;
}