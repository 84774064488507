.Notif__Table>table {
    @apply w-full border-collapse;
}

.Notif__Table>table>thead {
    @apply bg-slate-200 border-[0.4rem] border-transparent;
}

.Notif__Table>table>thead>tr {
    @apply text-left;
}

.Notif__Table>table>thead>tr>th {
    @apply py-[1rem] text-black font-[500] text-[1.6rem] max-md:px-[2rem];
}

.Notif__Table>table>thead>tr>th>div {
    @apply flex gap-[1rem] items-center;
}

.Notif__Table>table>thead>tr>th>div>svg {
    @apply cursor-pointer;
}

.Notif__Table>table>thead>tr>th:first-child {
    @apply pl-[3rem];
}

.Notif__Table>table>tbody> {
    @apply bg-slate-400;
}

.Notif__Table>table>tbody>tr {
    @apply text-left border-[0.1rem] border-slate-200 hover:bg-slate-200;
}

.Notif__Table>table>tbody>tr>td {
    @apply py-[1rem] text-slate-600 font-[400] text-[1.4rem] whitespace-nowrap max-lg:px-[2rem];
}

.Notif__Table>table>tbody>tr>td:first-child {
    @apply pl-[3rem];
}