.cardHome {
	@apply w-full flex flex-col items-center justify-center;
}

.cardHome>img {
	@apply w-[20rem] h-[9rem];
}

.cardHome h3 {
	@apply text-[1.8rem] font-[700] text-[#042E3E] mt-[4.5rem] mb-[2rem];
}

.cardHome p {
	@apply text-[1.6rem] font-[500] text-[#868686] text-center;
}

.cardWhyChooseUsHome {
	@apply flex flex-col items-center justify-center;
}

.cardWhyChooseUsHome h2 {
	@apply text-[21px] font-[400] text-[#01212E] mt-[4.5rem] mb-[2rem] text-left w-full;
}

.cardWhyChooseUsHome .flip-1 {
	@apply grid grid-cols-2 items-center justify-center place-items-start max-lg:grid-cols-1 max-lg:gap-[2rem];
}

.cardWhyChooseUsHome .flip-1 img {
	@apply max-lg:flex max-lg:items-center max-lg:justify-center;
}

.cardWhyChooseUsHome .flip-2 {
	@apply grid grid-cols-2 items-center justify-center place-items-end max-lg:grid-cols-1 max-lg:gap-[2rem];
}

.cardWhyChooseUsHome p {
	@apply text-[14px] font-[400] text-[#01212E] text-left;
}

.cardWhyChooseUsHome img {
	@apply max-w-[40rem] items-center justify-center max-lg:max-w-full;
}

/* Testimonial Card : Home */
.cardTestimonialHome {
	@apply max-lg:min-h-[40rem] h-[35rem] my-auto flex flex-col items-start justify-start gap-[2rem] px-4 text-left;
}

.cardTestimonialHome h2 {
	@apply text-[16px] font-[400] text-[#042E3E] text-left;
}

.cardTestimonialHome h3 {
	@apply text-[16px] font-[400] text-[#042E3E] text-right;
}

.cardTestimonialHome p {
	@apply text-[16px] font-[400] text-[#868686] text-left max-lg:text-[1.4rem];
}

/* .cardTestimonialHome svg {
	display: inline;
	vertical-align: middle;
} */

/* Card Service : Home */
.cardServiceHome {}

.cardServiceHome__Wrapper {
	@apply bg-[#042E3E] p-[2rem] w-fit rounded-[1rem];
}

.cardServiceHome__Wrapper img {
	@apply w-[5rem] h-[5rem];
}

.cardServiceHome h1 {
	@apply my-[4rem] text-[16px] font-[400] text-[#000] tracking-[0.19rem];
}

.cardServiceHome p {
	@apply text-[16px] font-[400] text-[#000];
}

/* Card Reports - Home */

.cardReportsHome {
	/* box-shadow: 0px 10px 9px 14px rgba(0, 0, 0, 0.2); */
	width: 100%;
	height: 50rem;
	display: block;
	background-color: #F1F1F1;
	background-size: 34px 34px;
	background-image:
		linear-gradient(to right, #D4D4D4 1px, transparent 1px),
		linear-gradient(to bottom, #D4D4D4 1px, transparent 1px);
	@apply relative transition-transform duration-500 ease-in overflow-hidden;
}

.cardReportsHome:hover {
	background-color: #042E3E;
	background-image: none;
	color: #FFF;
}

.cardReportsHome__Data {
	@apply text-[16px] pt-[2rem] inset-0 flex flex-col justify-center items-center transition-all duration-500 ease-in-out;
}

.cardReportsHome__Top {
	@apply z-10 flex-[1] w-full p-[2rem] flex flex-col justify-center items-start gap-y-[2.5rem];
}

.cardReportsHome__Top span {
	@apply text-[16px] font-[400];
}

.cardReportsHome__Top h1 {
	@apply text-[16px] font-[400] line-clamp-2;
}

.cardReportsHome__Bottom {
	@apply flex-[1] w-full flex flex-col pt-[15rem] px-[2rem] justify-center text-[16px] font-[400] text-white;
}

/* .cardReportsHome__Image {
	@apply absolute inset-x-0 bottom-0 transform transition-transform duration-500 ease-in-out;
} */

.cardReportsHome__Image {
	@apply absolute bottom-0 left-0 w-full h-1/2 overflow-hidden transform transition-transform duration-500 ease-in-out;
}

.cardReportsHome__Image img {
	@apply w-full h-full object-cover transition-all duration-200;
}

/* Teams Card : Teams */
.teamsCard {
	@apply relative w-full;
}

.teamCard__Initial {}

.teamCard__Initial img {
	@apply w-full h-[38.5rem] object-cover;
}

.teamsCard__Info {
	@apply absolute z-[10] bottom-[2rem] left-[2rem];
}

.teamsCard__Info h4 {
	@apply text-white text-[1.8rem] font-[700] uppercase;
}

.teamsCard__Info p {
	@apply text-white font-[500] text-[1.4rem];
}

.teamCard__HoverData {
	@apply absolute inset-x-0 bottom-0 transform translate-y-full transition-transform duration-500 ease-in-out group-hover:translate-y-[0%] bg-[#042E3E] group-hover:h-[100%] p-[2rem];
}

.teamCard__TopIcons {
	@apply flex gap-[1rem] items-center;
}

.teamCard__MidData {
	@apply py-[2rem] text-[1.3rem] font-[500] text-[#FFF];
}

/* Store Card */
.storeCard {
	@apply w-[30rem] bg-[#F9F9F9];
}

.storeCard>img {
	@apply w-full h-[30rem] object-cover;
}

.storeCard>h3 {
	@apply px-[1.2rem] my-[1.5rem] text-[#1E1E1E] text-[1.6rem] font-[500] line-clamp-3;
}

.storeCard__Bottom {
	@apply flex items-center justify-between py-[1rem] px-[1.2rem];
}

.storeCard__Bottom p {
	@apply text-[1.8rem] text-[#1E1E1E] font-[700];
}

.storeCard__Cart {
	@apply px-[1.5rem] py-[0.5rem] bg-[#D9D9D9] cursor-pointer;
}

/* Order Details Card */

.orderDetailsCard {
	@apply w-[30rem] bg-[#F9F9F9];
}

.orderDetailsCard>img {
	@apply w-full h-[30rem] object-cover;
}

.orderDetailsCard>h3 {
	@apply px-[1.2rem] my-[1.5rem] text-[#1E1E1E] text-[1.6rem] font-[500] line-clamp-3;
}

.orderDetailsCard__Bottom {
	@apply flex items-center justify-between py-[1rem] px-[1.2rem];
}

.orderDetailsCard__Bottom p {
	@apply text-[1.8rem] text-[#1E1E1E] font-[700];
}

.orderDetailsCard__Cart {
	@apply px-[1.5rem] py-[0.5rem] bg-[#D9D9D9] cursor-pointer;
}

/* Cart Card */
.cartCard {
	@apply flex gap-[5rem];
}

.cartCard__Left {
	@apply flex-[1];
}

.cartCard__Left img {
	@apply w-full h-[29rem] object-cover;
}

.cartCard__Right {
	@apply flex-[2];
}

.cartCard__Right h1 {
	@apply text-[#1E1E1E] font-[400] text-[2.2rem] line-clamp-3;
}

.cartCard__Options {
	@apply py-[5.5rem] flex items-center gap-[4.8rem];
}

.cartCard__Options>p {
	@apply text-[1.8rem] font-[700] text-[#1E1E1E];
}

.cartCard__Btns {
	@apply border-[#000000] border-[0.1rem] flex items-center gap-[1rem] text-[#1E1E1E] text-[1.8rem] font-[700];
}

.cartCard__Btns button {
	@apply px-[2rem] py-[1rem] outline-none border-none;
}

.cartCard__RemoveBtn {
	@apply border-none outline-none text-[1.8rem] font-[700] uppercase text-[#1E1E1E];
}

/* Blog Card */
.blogCard {
	@apply w-full border-t-[4px] border-[#5650D8] border-x-[1px] border-b-[1px] px-4 rounded-2xl flex items-center justify-between max-lg:flex-col;
}

.blogCard img {
	@apply w-full h-[23rem] object-cover;
}

.blogCard h3 {
	@apply text-[#1E1E1E] text-[2.2rem] font-[500] my-[1rem] tracking-wide;
}

.blogCard p {
	@apply text-[#1E1E1E] px-4 text-[1.6rem] font-[400] text-justify tracking-wider;
}

/* Blog Archive Card */
.blogArchiveCard {
	@apply flex items-start flex-col gap-[1rem] border-2 border-[#e5e5e5] overflow-hidden;
}

.blogArchiveCard__Img {
	@apply w-full h-[18rem] object-cover;
}

/* Admin Overview Card */
.adminOverviewCard {
	@apply hover:bg-slate-200 cursor-pointer p-[2rem] flex flex-col gap-[2rem] bg-slate-100 rounded-md shadow-md shadow-slate-200;
}

.adminOverviewCard__Icon {
	@apply w-full;
}

.adminOverviewCard__Details {
	@apply flex flex-col gap-y-[1rem];
}

.adminOverviewCard__Details h1 {
	@apply text-[2rem] font-[500];
}

.adminOverviewCard__Details p {
	@apply text-[1.6rem] font-[700] text-blue-500;
}

.adminOverviewCard__Info {
	@apply flex justify-between items-start;
}

/* NEWS CARD */
.newsCard {
	@apply p-[2rem] flex items-start flex-col gap-[1rem];
}

.newsCard>h3 {
	@apply text-[#042E3E] text-[1.8rem] font-[700] mb-[1rem] line-clamp-2 hover:text-[#5450d8];
}

.newsCard>p {
	@apply text-black text-[1.8rem] font-[400] mb-[1rem] line-clamp-2;
}

.newsCard .newsCard__Date {
	@apply flex items-center gap-[1rem] bg-[#042E3E] px-[2rem] py-[1rem] rounded-full;
}

.newsCard__Date>p {
	@apply text-white text-[1.4rem] font-[400];
}

.newsCard>span {
	@apply text-[1.8rem] w-fit font-[500] text-[#5451D6] hover:underline underline-offset-2;
}

.home__Reports--Bottom {
	display: grid;
	grid-auto-flow: row dense;
	grid-auto-columns: 1fr;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 0px 0px;
	border: 1px solid red;
}